<template>
  <div>
    <div class="home">
      <img class="homeImg" src="../../assets/DQ/PXL_20230303_231109219.jpg" />
      <div class="text">
        <h1>Who are we?</h1>
        <p>Hi Everyone!</p>
        <p>
          We are Tina & Rachael and we hope Rose & Rogue Beauty is as special to
          you as it is to us.
        </p>
        <p>
          Over the years, we have been to many salons & spas and loved leaving
          feeling refresed & revitalised. This is what we want to bring to you.
        </p>
        <p>
          We both started out as primary school teachers but moved on to pursue
          other dreams and interests.
        </p>
        <p>
          Tina has been in the industry for over 3 years and is constantly
          training, looking for new treatments and finding the best products to
          bring to you.
        </p>
        <p>
          Though we are a small independent salon, we hope you love us as much
          as we love pampering you and we hope you enjoy watching us grow.
        </p>
        <p>
          We love talking to all of you and are always keen to know what you are
          looking for so don't feel shy & drop us a message.
        </p>
        <p>We can't wait to meet you!</p>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.home {
  width: 75%;
  margin-left: 12.5%;
  padding-top: 5px;
  img {
    width: 35%;
    margin: 2%;
    display: inline-block;
    vertical-align: bottom;
  }
}
.text {
  display: inline-block;
  width: 60%;

  p {
    text-align: left;
  }
}
@media screen and (max-device-width: 973px) {
  .home {
    width: 100%;
    margin-left: 0px;
    padding-top: 0px;
    img {
      width: 35%;
      margin: 2%;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}
</style>
