<template>
  <div class="product">
    <img :src="pictureUrl" />
    <h3>{{ title }}</h3>
    <h4 v-show="isSwimSpa">Starting from</h4>
    <h4>{{ displayPrice(price, showWholeNumbers) }}</h4>
    <button @click="goToProduct()">View Details</button>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/money";

export default {
  computed: {
    pictureUrl() {
      if (this.pictureType === "computed") {
        var pictureUrl = this.$store.getters.getClientInfo?.pictureLocation;
        return pictureUrl + "/pics/"+this.id+"/cover.jpeg";
      } else {
        return this.picture;
      }
    },
  },
  props: {
    picture: String,
    id: Number,
    title: String,
    price: Number,
    isSwimSpa: Boolean,
    showWholeNumbers: Boolean,
    pictureType: String,
  },
  methods: {
    goToProduct() {
      this.$router.push("/product/" + this.id);
    },
    displayPrice(value, showWholeNumbers) {
      return formatPrice(value, showWholeNumbers);
    },
  },
};
</script>
