<template>
  <div class="brochure">
    <HeaderCommon />
    <div class="banner">
      <h1>{{ successTitle }}</h1>
    </div>
    <div class="succeed">
      <div>
        {{ successText }}
      </div>
    </div>
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
  },
  created() {
    this.successText = this.$store.getters.getClientInfo?.successText;
    this.successTitle = this.$store.getters.getClientInfo?.successTitle;
  },
};
</script>
<style lang="less">
.succeed {
  margin: auto;
  width: 50%;
}
</style>
