<template>
  <div>
    <h1>Contact us</h1>
    <h3>Phone No</h3>
    <p class="filler">07462907383</p>
    <h3>WhatsApp</h3>
    <p class="filler">
      Whatsapp us <a href="https://wa.me/447462907383">here</a>
    </p>
    <h3>Email</h3>
    <p class="filler">info@spaenvy.online</p>
  </div>
</template>
<style lang="less">
.filler {
  color: #fff;
  a {
    text-decoration: underline;
  }
}
</style>
