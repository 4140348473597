import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VCalendar from "v-calendar";
import { createMetaManager } from "vue-meta";
import { plugin, defaultConfig } from "@formkit/vue";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import {
  faCheck,
  faMapPin,
  faPhone,
  faEnvelope,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faSnapchat,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API;
axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("LogOut");
      return router.push("/login");
    }
  }
});

const metaManager = createMetaManager();

library.add(faSnapchat);
library.add(faInstagram);
library.add(faFacebook);
library.add(faCheck);
library.add(faEnvelope);
library.add(faMapPin);
library.add(faPhone);
library.add(faStar);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(
    plugin,
    defaultConfig({
      theme: "genesis",
    })
  )
  .use(metaManager)
  .use(VCalendar, {})
  .mount("#app");
