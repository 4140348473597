<template>
  <div class="home">
    <HeaderCommon />
    <h1>Nail treatments</h1>
    <p>
      At Drama Queen's Beauty Room, we are very passionate about nails and will
      use the best products and techniques to ensure that you have that
      glamourous finish.
    </p>

    <p>
      We offer everything from manicures, pedicures to Shellac and Plexigels
      using high quality nail polishes & gels.
    </p>
    <div class="waxone-reasons">
      <h3 class="TextModule__headline-h3___MN46G">
        <strong class="TextModule__bold___1ubC0">Why we use CND™?</strong
        ><br /><br />✓ Researched, developed, and formulated in the U.S.A<br />✓
        Advancing the industry with award-winning products&nbsp;<br />✓ The
        resource for innovation and the safest nail services<br />✓ The
        industry-leading advocate for the role of nail care&nbsp;<br />✓
        World-class education<br />✓ Vegan and Cruelty Free
      </h3>
    </div>

    <h1>Waxing Treatments</h1>
    <p>
      At Drama Queen's Beauty Room, all our waxing treatments are carried out by
      a certified waxing therapist using only the best products to ensure a
      comfortable and effective expirience.
    </p>
    <div class="waxone-reasons">
      <h2>Why we use Wax:One</h2>
      <p class="TextModule__paragraph___3AIU_">
        <span
          >✓ Sensitive skin friendly formula –rosin free with low working
          temperature reduces risk of ingrown hairs</span
        ><br /><br /><span
          >✓ No need for multiple waxes! Only 1x hot wax and 1x strip wax</span
        ><br /><br /><span
          >✓ Fantastic adhesion, melts quickly with no risk of going grainy or
          lumpy</span
        ><br /><br /><span
          >✓ Reduced treatment times – high performance formula sets within
          seconds</span
        ><br /><br /><span
          >✓ Thinner in application so you use less product and not compromise
          on performance</span
        ><br /><span> </span><br /><span
          >✓ Low working temperature and rosin-free formula make it perfect for
          sensitive skin</span
        ><br /><span> </span><br /><span
          >✓ Made in the UK by a team of beauty experts with over 100+ years of
          industry experience</span
        >
      </p>
    </div>
    <div class="vidContainer">
      <iframe
        width="80%"
        height="640"
        src="https://www.youtube.com/embed/yTLpPxQw-nI"
        title="wax:one professional waxing"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>

    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
  },

  data() {
    return {
      client: "",
    };
  },
  created() {
    //this.client = Vue.config.pwa.name;
  },
};
</script>
<style lang="less"></style>
