<template>
  <div class="brochure">
    <HeaderCommon />
    <div class="banner">
      <h1>Please enter your details</h1>
    </div>
    <div class="requestForm">
      <label for="name">Name</label>
      <input id="name" v-model="name" />
      <div class="error" v-bind="nameError" />
      <label for="email">Email</label>
      <input id="email" v-model="email" />
      <div class="error">{{ emailError }}</div>
      <label for="telephone">Phone</label>
      <input id="telephone" v-model="telephone" />
      <div class="error">{{ phoneError }}</div>
      <label for="address">Address</label>
      <textarea id="address" v-model="address" />
      <label for="message">Message</label>
      <textarea id="message" v-model="message" />
      <button @click="order">Order</button>
    </div>
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import emailjs from "emailjs-com";
import { formatPrice } from "../utils/money";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
  },
  data() {
    return {
      name: "",
      telephone: 0,
      address: "",
      email: "",
      message: "",
      nameError: "",
      emailError: "",
      phoneError: "",
    };
  },
  methods: {
    validation() {
      var validationFailure = false;
      if (this.name == "") {
        this.nameError = "Please enter Name";
        validationFailure = true;
      }
      if (this.email == "") {
        validationFailure = true;
        this.emailError = "Please enter Email";
      }
      if (this.telephone == "") {
        validationFailure = true;
        this.phoneError = "Please enter Phone number";
      }
      return validationFailure;
    },
    order() {
      // Dev Credentials here
      //"template_rdkqpyv",
      //"service_hc5jygb",
      //"3NElk_GVPFhGh-_yK"
      if (!this.validation()) {
        emailjs
          .send(
            "service_gxbgjji",
            "template_nzm7udh",
            {
              address: this.address,
              telephone: this.telephone,
              email: this.email,
              name: this.name,
              message: this.message,
              cart: this.$store.getters.getCartHtml,
              cartPrice: formatPrice(this.$store.getters.getCartTotal),
            },
            "MD96GQF_abKF-cw6z"
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.text);
              this.$router.push("/order/success");
            },
            (error) => {
              console.log("FAILED...", error.text);
              this.$router.push("/order/fail");
            }
          );
      }
    },
  },
};
</script>
<style lang="less">
@accent-color: #1496bc;
h1 {
  color: @accent-color;
}

.requestForm {
  margin: auto;
  color: @accent-color;
  width: 50%;
  input {
    display: block;
    border: 1px solid #1496bc;
    width: 100%;
    margin: 10px;
    color: #1496bc;
  }
  textarea {
    border: 1px @accent-color solid;
    width: 100%;
    margin: 10px;
  }
}

@media screen and (max-device-width: 645px) {
  .requestForm {
    width: 100%;
  }
  .banner {
    h1 {
      font-size: 14px;
    }
  }
}
</style>
