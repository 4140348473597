<template>
  <div class="home">
    <HeaderCommon />
    <h1>Galleries</h1>
    <GalleyContent />
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import GalleyContent from "@/components/DramaQueen/GalleyContent.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    GalleyContent,
    FooterCommon,
  },

  data() {
    return {
      client: "",
    };
  },
  created() {
    //this.client = Vue.config.pwa.name;
  },
};
</script>
<style lang="less">
h1,
h2,
span {
  color: #000;
}

.vidContainer {
  margin: 20px 0px;
  background-color: #000;
  padding: 20px;
}

@media screen and (max-device-width: 920px) {
  .vidContainer {
    margin: 0px;
    padding: 0px;
  }
}
</style>
