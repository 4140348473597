import axios from "axios";

export const categoriesStore = {
  state: () => ({ categories: [] }),
  actions: {
    async callCategories({ commit }) {
      const { data } = await axios.get("categories/list");
      if (data) {
        commit("setCategoriesList", data);
      }
    },
  },
  mutations: {
    setCategoriesList(state, categories) {
      state.categories = categories;
    },
  },
  getters: {
    getCategories(state) {
      return state.categories;
    },
  },
};
