<template>
  <div class="dqheader">
    <div class="logoWrapper">
      <img src="../../assets/DQ/Logo.png" alt="Main logo" v-if="name === 'RRB'" />
      <img src="../../assets/BBG/Logo.png" alt="Main logo" v-if="name === 'BBG'" />
    </div>
    <div class="addressWrapper">
      <div class="line">
        <font-awesome-icon icon="fa-solid fa-map-pin" />
        <h3>{{ address }}</h3>
      </div>
      <div class="line">
        <font-awesome-icon icon="fa-solid fa-envelope" />
        <h3>{{ email }}</h3>
      </div>
      <div class="line">
        <font-awesome-icon icon="fa-solid fa-phone" />
        <h3>{{ telephone }}</h3>
      </div>
    </div>
    <div class="followWrapper" v-if="socialMedia != null">
      <div class="bold">
        Follow us on social media for the latest updates & offers
      </div>
      <div class="social" v-if="socialMedia.facebook != undefined">
        <font-awesome-icon icon="fa-brands fa-facebook" />
        <a :href="socialMedia.facebook.link"
          >{{ socialMedia.facebook.display }}</a
        >
      </div>
      <div class="social" v-if="socialMedia.instagram != undefined">
        <font-awesome-icon icon="fa-brands fa-instagram" />
        <a :href="socialMedia.instagram.link"
          >{{ socialMedia.instagram.display }}</a
        >
      </div>
    </div>
    <div class="menu_area">
      <div class="wrapper">
        <div id="navigation">
          <div id="navbtn">menu</div>
          <ul class="dnav-menu" id="menu-nenu">
            <li class="site-nav__item site-nav--active">
              <a href="/" class="site-nav__link1" data-meganav-type="child">
                Home
              </a>
            </li>
            <!--li class="site-nav__item">
              <router-link to="/nails">Treatment & Products</router-link>
            </li-->
            <li class="site-nav__item" v-if="name === 'RRB'">
              <router-link to="/galleries">Gallery</router-link>
            </li>
            <li class="site-nav__item" v-if="name === 'BBG'">
              <router-link to="/haircare">Hair Care</router-link>
            </li>
            <li class="site-nav__item" v-if="name === 'BBG'">
              <router-link to="/body">Body</router-link>
            </li>
            <li class="site-nav__item" v-if="name === 'BBG'">
              <router-link to="/extensions">Extensions</router-link>
            </li>
            <li class="site-nav__item" v-if="name === 'BBG'">
              <router-link to="/groceries">Groceries</router-link>
            </li>
            <li class="site-nav__item">
              <router-link to="/reviews">Reviews</router-link>
            </li>
            <li class="site-nav__item" v-if="name === 'RRB'">
              <router-link to="/booking">Book Now</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderDQ",

  data() {
    return {
      telephone: "",
      email: "",
      address: "",
      socialMedia: null,
      name: "",
    };
  },
  created() {
    this.telephone = this.$store.getters.getClientInfo?.telephoneNo;
    this.email = this.$store.getters.getClientInfo?.email;
    this.address = this.$store.getters.getClientInfo?.address;
    this.socialMedia = this.$store.getters.getClientInfo?.socialMedia;
    this.name = this.$store.getters.getClientInfo?.name
  },
};
</script>
