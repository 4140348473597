import axios from "axios";

export const treatmentsStore = {
  state: () => ({ treatments: [] }),
  actions: {
    async callActiveTreatments({ commit }) {
      const { data } = await axios.get("treatments/list?activeOnly=1");
      if (data) {
        commit("setTreatementsList", data);
      }
    },
  },
  mutations: {
    setTreatementsList(state, treatments) {
      state.treatments = treatments;
    },
  },
  getters: {
    getTreatments(state) {
      return state.treatments;
    },
  },
};
