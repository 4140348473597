<template>
  <div>
    <footer>
      <div class="f-content">
        <div class="footer_logo">
          <img
            src="../../assets/DQ/Logo.png"
            alt="Main logo"
            v-if="name === 'RRB'"
          />
          <img
            src="../../assets/BBG/Logo.png"
            alt="Main logo"
            v-if="name === 'BBG'"
          />
        </div>
        <p>
          © 2023, <a href="/" title="">{{ title }}</a> All rights
          reserved.
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "FooterDQ",

  data() {
    return {
      title: '',
      name: "",
    };
  },
  created() {
    this.title = this.$store.getters.getClientInfo?.title;
    this.name = this.$store.getters.getClientInfo?.name;
  },
};
</script>
