<template>
  <div class="card">
    <div class="container">
      <font-awesome-icon
        v-for="i in stars"
        v-bind:key="i"
        icon="fa-solid fa-star"
        class="star"
      />
      <h3>
        <b>{{ reviewer }} says...</b>
      </h3>
      <p>{{ review }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReviewCard",
  props: {
    review: String,
    reviewer: String,
    stars: Number,
  },
};
</script>