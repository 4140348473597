<template>
  <h3>We pride ourselves on our customer service</h3>
  <p>
    At SpaEnvy we look after our customers, we make sure that every need is met
    and our customers feel special. From our customer service to the fitters
    that come and install your product, we make sure you are in the best of
    hands.
  </p>
  <p>
    Once your spa is all installed you will be the envy of your neighbors and
    friends. So what are you waiting for? Dive in and make a splash...
  </p>
  <p>
    We are an online service however our products can be seen at our storage
    facilities in Garforth, Glasgow.
  </p>
</template>
