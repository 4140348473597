<template>
  <div class="reviews">
    <HeaderCommon />
    <h1>Reviews</h1>
    <h3>
      {{ blurb }}
    </h3>

    <ReviewCard
      v-for="review in reviews.filter(
        (x) => x.client === name
      )"
      :key="review.reviewer"
      :review="review.review"
      :reviewer="review.reviewer"
      :stars="review.stars"
    />

    <h4>
      Want to leave us a review and see it on here please contact us on
      {{ email }}
    </h4>

    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import ReviewCard from "@/components/Controls/ReviewCard.vue";
import allReviews from "@/data/reviews.js";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    ReviewCard,
    FooterCommon,
  },

  setup() {
    let reviews = ref(allReviews);
    return { reviews };
  },

  data() {
    return {
      client: "",
    };
  },
  created() {
    this.email = this.$store.getters.getClientInfo?.email;
    this.blurb = this.$store.getters.getClientInfo?.reviewBlurb;
    this.name = this.$store.getters.getClientInfo?.name
  },
};
</script>