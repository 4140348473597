<template>
  <div class="spa">
    <HeaderCommon />
    <div class="OuterGrid">
      <div class="sideBar">
        <h3>Options</h3>
        <label> Manufacturer </label>
        <select
          class="form-control"
          v-model="manufacturerFilter"
          @change="onChange($event)"
        >
          <option
            v-for="manufacturer in manufacturers"
            v-bind:key="manufacturer"
            v-bind:value="manufacturer"
          >
            {{ manufacturer }}
          </option>
        </select>
        <div />
        <div />
      </div>
      <div class="results">
        <div v-for="product in products" :key="product.id" class="result">
          <ProductCard
            :id="product.id"
            :picture="product.picture"
            :title="product.title"
            :price="product.price"
            :showWholeNumbers = true
          />
        </div>
      </div>
    </div>
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import ProductCard from "@/components/Controls/ProductCard.vue";
import allProducts from "@/data/spas.js";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
    ProductCard,
  },
  setup() {
    const products = ref(allProducts);
    return { products };
  },
  methods: {
    onChange(event) {
      console.log(event.target.value);
    },
  },
  data() {
    return {
      manufacturers: ["All", "Oasis Spa", "Sunbeach Spa"],
      manufacturerFilter: "All",
      jetFilter: 69,
      capFilter: 4,
    };
  },
};
</script>
<style lang="less">
.spa {
  .OuterGrid {
    display: inline-block;
    margin-top: 15px;
    .sideBar {
      height: 100%;
      display: none; // TODO: Fix Filters
      float: left;
      width: 15%;
      padding: 25px;
      h3 {
        color: #1496bc;
        text-align: left;
      }
      label {
        color: #1496bc;
        margin: 10px 25px 10px -72px;
        font-size: 16px;
        text-align: left;
      }
      select {
        color: #1496bc;
        border: 1px solid #1496bc;
        margin: 10px 25px 10px -17px;
      }
      .vue-slider {
        margin: 15px 0;
      }
    }
    .results {
      display: inline-block;
      width: 80%;
      .result {
        display: inline-block;
        width: 30%;
      }
    }
  }
}
</style>
