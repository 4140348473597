<template>
  <div class="header">
    <HeaderSE v-if="name === constants.SPAENVY" />
    <HeaderDQ v-if="name === constants.RRB || name === constants.BBG" />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderSE from "@/components/SpaEnvy/HeaderSE.vue";
import HeaderDQ from "@/components/DramaQueen/headerDQ.vue";
import { clients } from "@/data/constants";

export default {
  name: "HomeView",
  components: {
    HeaderSE,
    HeaderDQ,
  },

  data() {
    return {
      name: "",
      constants: clients,
    };
  },
  created() {
    this.name = this.$store.getters.getClientInfo?.name;
    this.constants = clients;
  },
};
</script>
