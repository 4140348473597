export default [
  {
    id: 201,
    title: "Sunbeach SB366L LUX Silver White Marble",
    price: 7895,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/15/cover/JiuSZO9sg9BYXsr9tfq5quW9AZ48AFR3Mn0MhP10.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/public/master-products/15/ESd9DZmJOfdjuUiAUV7EcEZj1bFaYbYghHWS4Wma.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/15/ZxF4dR3jd0QC2CCrgEyO4ShVw6hD0CLc6aFNE1UK.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/15/oS7Oj37iy7OMjCvofKTLGlydJpAc7qJopgrCoJMV.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/15/Hn9EQt8aiJxq9a5BwqyxJBs4L6C3WPKw0hPyYILU.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/15/foIAgaCGROvjITgtUsLatnyxkg4vnlslalS67iqy.png?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/15/DVkw1Hz4T5bZzCrt9qZRrgt4LvxkcCQT8K5yu5h4.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p> </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 5 persons</li>
    <li>Seating: 4x hydro-therapy seats &amp; 1 hydro-massage lounger</li>
    <li>Dimensions: 2100 x 2100 x 800mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Balboa TP400 tamper-proof top panel</li>
    <li>Balboa Controls: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 3</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10'</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 50 targeted water jet</li>
    <li>Rotary Jets: 24</li>
    <li>Directional hydro-therapy jets: 26</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Top water line lights: 20 (colour changing)</li>
    <li>LED Waterfall: 1</li>
    <li>Big underwater LED: 1 (colour changing)</li>
    <li>Cup/glass holders with LED </li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 202,
    title: "Sunbeach SB343DL Pro Premium Range",
    price: 9995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/18/cover/LBCmNhQUmRAZYLhUam3QMcUb0sngAXV51UwYfzuC.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p></p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 6 persons</li>
    <li>Seating: 4x hydro-therapy seats and 2 x hydro-therapy loungers</li>
    <li>Dimensions: 2300 x 2300 x 950mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Balboa SpaTouch 3 Interactive display</li>
    <li>Balboa Controls: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 4</li>
    <li>Diverter valve: 1</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 58 targeted water jet</li>
    <li>Rotary Jets: 26</li>
    <li>Directional hydro-therapy jets: 32</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Top water line lights: 24 Fibre Optic (colour changing)</li>
    <li>Big underwater LED: 1 (colour changing)</li>
    <li>Waterfall with LED: 1 (colour changing)</li>
    <li>Speakers: 2 x bba2 Balboa Bluetooth and Subwoofer</li>
    <li>Ice bucket: 1</li>
    <li>Cup/Glass holders with LED: 2 (colour changing)</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 203,
    title: "SB344S by Sunbeach Spas 5 Person Hot Tub",
    price: 5295,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/1/cover/NEbQA3QacWS31m5QiL3dTyRsOiJ5hVQhFdMqmngQ.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p><strong>Information</strong><br></p>
    <ul>
    <li>Seating Capacity: 5 persons</li>
    <li>Seating: 5 x hydro-therapy seats</li>
    <li>Dimensions: 2150 x 1800 x 800mm</li>
    <li>Water Capacity: 1100L</li>
    <li>Power: 13amp plug and play or 20amp available</li>
    <li>Balboa Controls: 2kw heater</li>
    <li>Model: Colour: Silver, White Marble</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 24 Stainless steel targeted water jet</li>
    <li>Rotary Jets: 14</li>
    <li>Directional Jets: 10</li>
    <li>Circulation Jet: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing)</li>
    <li>Cup holders: 4</li>
    <li>LED top rail lights: 13 (colour changing)</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 204,
    title: "SB353L-Standard 6 Person",
    price: 5999,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/12/cover/Uo18g8jUYpejDKWoPZt9ik01ep6sjU8NTikx0pEy.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p><span data-mce-fragment="1"></span><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 6&nbsp;persons</li>
    <li>Seating: 6&nbsp;x hydro-therapy seats &amp; 1 hydro-massage lounger</li>
    <li>Dimensions: 2000&nbsp;x 2000&nbsp;x 800mm</li>
    <li>Power: 32amp</li>
    <li>Balboa Controls: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 29&nbsp;Stainless steel &amp; grey targeted water jet</li>
    <li>Rotary Jets: 15</li>
    <li>Directional hydro-therapy jets: 14</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing)</li>
    <li>Cup holders: 2</li>
    <li>LED&nbsp;top rail lights: 17&nbsp;(colour changing)</li>
    <li>LED Waterfall: 1&nbsp;</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 205,
    title: "SB353L PRO 6 Person Hot Tub",
    price: 6599,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/13/cover/FuxtExTVcRi4ThZwrwup9K2kWFef5YLFDV8aWjt2.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 6 persons</li>
    <li>Seating: 6x hydro-therapy seats &amp; 1 hydro-massage lounger</li>
    <li>Dimensions: 2000 x 2000 x 800mm</li>
    <li>Power: 13amp plug and play or 20amp</li>
    <li>Control system: Balboa TP400 tamper-proof top panel</li>
    <li>Balboa Controls: 2kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 29 chrome &amp; grey targeted water jet</li>
    <li>Rotary Jets: 15</li>
    <li>Directional hydro-therapy jets: 14</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing)</li>
    <li>Cup holders: 2</li>
    <li>LED top rail lights: 17 (colour changing)</li>
    <li>LED Waterfall: 1</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 206,
    title: "SB354S by Sunbeach Spas 7 Person Hot Tub",
    price: 5995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/7/cover/aWmghxIDgE6hPZI3l7xD6y3fjDLNyS510cuwKebp.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p> </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 7 persons</li>
    <li>Dimensions: 2000 x 2000 x 800mm</li>
    <li>Power: 13amp plug and play or 20amp switch over available</li>
    <li>Control system: Balboa Controls</li>
    <li>Balboa Controls: 2kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1 x 3HP</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Preasure release valve: 2</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 26 Stainless steel &amp; grey targeted water jet</li>
    <li>Rotary Jets: 14</li>
    <li>Directional hydro-therapy jets: 12</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing</li>
    <li>Cup holders: 4</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 207,
    title: "SB355L by Sunbeach Spas 5 Person Hot Tub",
    price: 5799,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/61/cover/vvxbmY67IXnJvpy75zpgODOus5oO5YuopxUcJcXn.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 5 persons</li>
    <li>Seating: 5 x hydro-therapy seats &amp;1 hydro-massage lounger</li>
    <li>Dimensions: 2250 x 2250 x 800mm</li>
    <li>Power: 13amp plug and play or 20amp</li>
    <li>Control system: Balboa TP400 tamper-proof top panel</li>
    <li>Balboa Controls: 2kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2 – double filtration system</li>
    <li>Insulation: All 4 sides Super R10’</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 27Stainless steel &amp; grey targeted water jet</li>
    <li>Rotary Jets: 17</li>
    <li>Directional hydro-therapy jets: 10</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing)</li>
    <li>Cup holders: 4</li>
    <li>Top water line LED's: 13 (colour changing)</li>
    <li>LED Waterfall:</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 208,
    title: "SB415R by Sunbeach Spas 6 Person Hot Tub",
    price: 5995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/14/cover/KXOOmDbQeiYwDGrewkYs7bWsPCeUNlNgRZGfbmln.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p> </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 6 persons</li>
    <li>Seating: 6 x hydro-therapy seats </li>
    <li>Dimensions: 2000 x 2000 x 900mm</li>
    <li>Power: 13amp plug and play or 20amp</li>
    <li>Control system: Balboa TP400 tamper-proof top panel</li>
    <li>Balboa Controls: 2kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 15 Stainless steel &amp; grey targeted water jet</li>
    <li>Rotary Jets: 8</li>
    <li>Directional hydro-therapy jets: 7</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing</li>
    <li>Cup holders: 3</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 209,
    title: "SB639S - 7 Person Executive Range Hot Tub",
    price: 10495,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/20/cover/DveRfuMREBo3b9FRHeKBtRPSeku3C6fm4vz5S9bj.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p> </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 7 persons</li>
    <li>Seating: 3 x hydro-therapy seats and 2 hydro-massage loungers</li>
    <li>Dimensions: 2300 x 2300 x 1050mm</li>
    <li>Power: 32amp</li>
    <li>Balboa Controls: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2 x 3HP/0.35hp Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 3</li>
    <li>Diverter valve: 2</li>
    <li>Waterfall valve: 1</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 45 targeted water jet</li>
    <li>Rotary Jets: 21</li>
    <li>Directional hydro-therapy jets: 24</li>
    <li>Circulation Jet: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Top water line lights: 25 Fibre Optic (colour changing)</li>
    <li>Big underwater LED: 1 (colour changing)</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 210,
    title: "SB640L - Li5 Platinum 5 Person Executive Range Hot Tub",
    price: 10495,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/21/cover/NEHtdQmvJtXjnNPsXVGAj6owCE9g6LpPWL6iJRuj.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p> </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 5 persons</li>
    <li>Seating: 4 x hydro-therapy seats and 1 x hydro-massage loungers</li>
    <li>Dimensions: 2300 x 2300 x 1050mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Balboa SpaTouch 3 Interactive display</li>
    <li>Balboa Controls: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 4</li>
    <li>Diverter valve: 2</li>
    <li>Waterfall valve: 1</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 43 targeted water jet</li>
    <li>Rotary Jets: 22</li>
    <li>Directional hydro-therapy jets: 18</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Top water line lights: 25 Fibre Optic (colour changing)</li>
    <li>Big underwater LED: 1 (colour changing)</li>
    <li>Clear stream fountain: 3</li>
    <li>Speakers: Balboa BBA2 Bluetooth Speaker </li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 211,
    title: "SB641 - Di5 Platinum 5 Person Executive Range Hot Tub",
    price: 10495,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/22/cover/D3PynvQwzlwhWBFz3zbuNLyAsbT9nAXYhbqcmbn3.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p><strong>Information</strong><br></p>
    <ul>
    <li>Seating Capacity: 5 persons</li>
    <li>Seating: 3 x hydro-therapy seats and 2 x hydro-massage loungers</li>
    <li>Dimensions: 2300 x 2300 x 1050mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Balboa SpaTouch 3 Interactive display</li>
    <li>Balboa Controls: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 4</li>
    <li>Diverter valve: 2</li>
    <li>Waterfall valve: 1</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 55 targeted water jet</li>
    <li>Rotary Jets: 19</li>
    <li>Directional hydro-therapy jets: 33</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Top water line lights: 25 Fibre Optic (colour changing)</li>
    <li>Big underwater LED: 1 (colour changing)</li>
    <li>Clear stream fountain: 3</li>
    <li>Speakers: Balboa BBA2 Bluetooth Speaker</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 212,
    title: "Sunbeach SB388DL-LUX 4 Seater",
    price: 7799,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/17/cover/WGN50ZGme5GpAhVvCrxuai7zaZsTrTKzBFoOdlWn.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p>The Sunbeach SB388DL Pro is an Award Winning 4-person hot tub with 2 hydro-therapy seats and 2 hydro-therapy loungers. Designed in the UK using American Aristech Acrylic and American Balboa controls and heater. The Sunbeach SB388DL Pro is a 32amp hot tub which has been designed to be powerful enough to exceed all your needs.</p>
    <p><strong>UK Pool &amp; Spa Awards - Energy Efficient ‘Gold Standard’</strong></p>
    <p>The Sunbeach Spas range all benefit from the Award-Winning super energy saving R10 insulation technology.  After years of development the R10 technology is now fitted to every Sunbeach Spa which along with the fantastic all-season top covers make the perfect choice for energy efficiency. For more information regarding hot tub energy efficiency, please read our recent blog, </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 4 persons</li>
    <li>Seating: 2 x hydro-therapy seats &amp; 2 hydro-massage loungers</li>
    <li>Dimensions: 2150 x 2100 x 800mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Balboa TP600</li>
    <li>Balboa Controls: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 47 Chrome and Grey</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing)</li>
    <li>Cup holders: 2</li>
    <li>Top water line LED's: 20 (colour changing)</li>
    <li>LED Waterfall: 1 </li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 213,
    title: "Sunbeach SB345S-LUX",
    price: 13999,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/106/cover/lMZLhUPqSzLVkn7F9AJJIJUEbtQF06FCcL7EBysl.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p>&nbsp;</p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 12 persons</li>
    <li>Seating: 9&nbsp;x hydro-therapy seats&nbsp;</li>
    <li>Dimensions: 2300 x 3000&nbsp;x 930mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Balboa Spa Touch 3 Control Panel</li>
    <li>Acrylic Colour: <span data-mce-fragment="1">Silver White Marble</span></li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>BBA2 Sound System</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2&nbsp;x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Fountain pump: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 60mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 3</li>
    <li>Diverter valve: 1</li>
    <li>Filters: 2 - double filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 74&nbsp;targeted water jet</li>
    <li>Rotary Jets: 40</li>
    <li>Directional hydro-therapy jets: 34</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Top water line lights: 32 Fibre Optic&nbsp;(colour changing)</li>
    <li>Big underwater LED: 1 (colour changing)</li>
    <li>Clear stream fountain jets: 4</li>
    <li>Pillows: 3</li>
    <li>Speakers: 2 x bba2 Balboa Speakers&nbsp;</li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 214,
    title: "Sunbeach SB356DL 2 Person Plug and Play Hot Tub",
    price: 4999,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/9/cover/Wp16th0jeAcfBPSzVXbgLldBzoky1zIA7j1nilaw.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p><span data-mce-fragment="1">The Sunbeach SB356DL is an Award Winning 2-person hot tub with 2 hydro-therapy lounger. Designed in the UK using American Aristech Acrylic and American Balboa controls and heater. The Sunbeach SB356DL is a 13amp plug and play hot tub which has been designed to be powerful enough to exceed all your needs.</span></p>
    <p><strong>UK Pool &amp; Spa Awards - Energy Efficient ‘Gold Standard’</strong></p>
    <p>The Sunbeach Spas range all benefit from the Award-Winning super energy saving R10 insulation technology.  After years of development the R10 technology is now fitted to every Sunbeach Spa which along with the fantastic all-season top covers make the perfect choice for energy efficiency. For more information regarding hot tub energy efficiency, please read our recent blog, </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 2 persons</li>
    <li>Seating: 2 hydro-massage loungers</li>
    <li>Dimensions: 1900 x 1350 x 780mm</li>
    <li>Power: 13amp Plug and Play</li>
    <li>Balboa Controls: 2kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 1 filtration system</li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 22 Chrome and Grey</li>
    <li>Rotary Jets: 5</li>
    <li>Directional hydro-therapy jets: 17</li>
    <li>Circulation Jets: 1</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing)</li>
    <li>Cup holders: 2</li>
    <li>Top water line LED's: 13 (colour changing)</li>
    <li>LED Waterfall: 1 </li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 215,
    title: "Sunbeach SB377S-LUX Hot Tub",
    price: 13995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/16/cover/uxoCLVn2J4SYXpoJDRcTHunw2n0NTKCdry82R2iB.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 7 persons</li>
    <li>Seating: 2 x hydro-therapy seats &amp; 2 hydro-massage loungers</li>
    <li>Dimensions: 2100 x 2100 x 800mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Balboa TP400</li>
    <li>Balboa Controls: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Graphite Everlast or Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2 x 3HP/0.26KW Water pump</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 1 </li>
    <li>Insulation: All 4 sides Super R10</li>
    <li>Base: Solid PVC and R10 Insulation</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 53 Chrome and Grey</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED light: 1 (colour changing)</li>
    <li>Cup holders: 2</li>
    <li>Top water line LED's: 20 (colour changing)</li>
    <li>LED Waterfall: 1 </li>
    </ul>`,
    manufacturer: "Sunbeach Spas",
  },
];
