export const fillNumbericalValues = function (minValue, maxValue) {
  let returnArray = [];
  for (var i = minValue; i <= maxValue; i++) {
    returnArray.push(padNumber(i));
  }
  return returnArray;
};

export const padNumber = function (value) {
  if (value.toString().length === 1) {
    // Pad Value if this is a single digit
    return "0" + value.toString();
  } else {
    return value.toString();
  }
};
