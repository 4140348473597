<template>
  <div>
    <div class="bb-blurb">
      <div class="text">
        <h1>About us</h1>
        <p>
          Welcome to BB Beauty and Groceries, where we pride ourselves on
          catering to your needs for Hair, Beauty, and Afro-Caribbean food
          products. With a steadfast dedication to excellence, we've curated an
          unparalleled collection of items to meet your diverse preferences and
          requirements.
        </p>
        <p>
          Our carefully selected array of products have been meticulously
          crafted to ensure that every unique skin and hair type is not only
          accommodated but celebrated. Whether you have luscious curls, sleek
          locks, or any other hair texture, our products are tailored to provide
          the utmost care and nourishment, ensuring you radiate with health and
          vitality.
        </p>
        <p>
          Why not give your hair the best care it deserves and make your face,
          body & vitality glow with our fantastic product range.
        </p>
        <p>
          These products not only smell amazing but also contains sumptious
          butter ingredients that repair and maintain beautiful skin & hair.
        </p>
      </div>
    </div>
    <img src="../../assets/BBG/shop1.jpg" />
    <img src="../../assets/BBG/shop2.jpg" />
    <img src="../../assets/BBG/shop3.jpg" />
    <div class="vapes">
      <h3>We now sell vapes, please contact email us to inquire</h3>
      <img src="../../assets/BBG/vapes.jpeg" />
    </div>
  </div>
</template>

<style lang="less" scoped>
.bb-blurb {
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
}

img {
  width: 33%;
  margin: 1px;
  padding-bottom: 15px;
  border-bottom: 1px solid #fe1392;
}
.text {
  h1 {
    border-bottom: 1px solid #fe1392;
  }
  display: inline-block;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  p {
    text-align: left;
    margin-left: 1px;
  }
}
@media screen and (max-device-width: 973px) {
  .bb-blurb {
    width: 100%;
    margin-left: 0px;
    padding-top: 0px;
    img {
      width: 35%;
      margin: 2%;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

@media screen and (max-device-width: 545px) {
  .bb-blurb {
    width: 100%;
    margin-left: 0px;
    padding-top: 0px;
    p {
      font-size: 14px;
    }
  }
  img {
    width: 31%;
    margin: 1px;
    display: inline-block;
    vertical-align: bottom;
  }
}
</style>
