import { padNumber } from "./number";

export const formatTimeToHours = function (minutes) {
  var result = "";
  var hours = Math.trunc(minutes / 60);
  var remainder = minutes % 60;

  if (hours > 0) {
    result = `${hours} hr `;
  }

  if (remainder > 1) {
    result += `${remainder} min`;
  }

  return result;
};

export const toSqlDate = function (vCalenderDate) {
  var month = vCalenderDate.getMonth() + 1;
  var day = vCalenderDate.getDate();
  var year = vCalenderDate.getYear() + 1900;
  return `${year}-${padNumber(month)}-${padNumber(day)}`;
};
