<template>
  <div class="awardRack">
    <img src="../../assets/bestbuy.png" width="150px" />
    <img src="../../assets/hsg.png" width="150px" />
    <img src="../../assets/ukpool.png" width="150px" />
    <img src="../../assets/whatpoo.png" width="150px" />
  </div>
</template>

<style lang="less">
.awardRack {
  display: inline-block;
  padding-bottom: 15px;
  img {
    margin-left: 10px;
  }
}
</style>
