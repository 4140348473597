export default [
  {
    name: "RRB",
    failureText: `Your booking has not made it through our system due to technical reasons, 
  please call us on 07435 997096 and we will book you in.`,
    failureTitle: "Sorry we were unable to process your booking",
    successText:
      "You should recieve an email confirmation of your appointment, we look forward to seeing you",
    successTitle: "You are all booked in",
    telephoneNo: "07435 997096",
    title: "Rose & Rogue Beauty",
    email: "info@roseandroguebeauty.co.uk",
    reviewBlurb:
      "We are passionate and always look to bring you the best in treatment and care but don't take our word for it, please take a look at our reviews!",
    address: "Boxalls Lane, Aldershot, Hampshire, GU11 3QJ",
    socialMedia: {
      instagram: {
        display: "@roseandroguebeauty",
        link: "https://www.instagram.com/roseandroguebeauty/",
      },
      facebook: {
        display: "Roseandroguebeauty",
        link: "https://www.facebook.com/profile.php?id=100088132560090",
      },
    },
    usesCart: false
  },
  {
    name: "SpaEnvy",
    failureTitle: "Order not processed",
    failureText: `Your order has not reached our agents, please either call us on
  07462907383 or email us at info@spaenvy.co.uk and we can handle your
  order from there.`,
    successText: `Your order has been emailed out to our agents who will get in contact
  with you to discuss your order and payment. If you do not hear from our
  agents, please either call us on 07462907383 or email us at
  info@spaenvy.co.uk`,
    successTitle: "Order sucessful",
    title: "SpaEnvy",
    usesCart: true
  },
  {
    name: "BBG",
    failureTitle: "Order not processed",
    failureText: `Your order has not reached our agents, please either call us on
  07462907383 or email us at info@spaenvy.co.uk and we can handle your
  order from there.`,
    successText: `Your order has been emailed out to our agents who will get in contact
  with you to discuss your order and payment. If you do not hear from our
  agents, please either call us on 07462907383 or email us at
  info@spaenvy.co.uk`,
    successTitle: "Order sucessful",
    title: "BB Beauty and Groceries",
    email: "bbbeautyandgroceries@gmail.com",
    address: "159 Ash Rd, Aldershot GU12 4DB",
    telephoneNo: "(+44) 07557 028259",
    socialMedia: {
      instagram: {
        display: "@bbbeautyandgroceries",
        link: "https://instagram.com/bbbeautyandgroceries?igshid=MWlsM2wwMzlnaGxzNg==",
      },
      facebook: {
        display: "BB Beauty and Groceries",
        link: "https://www.facebook.com/profile.php?id=100091867938714",
      },
    },
    reviewBlurb:
    "Please see what our customers have to say about us",
    pictureLocation: "https://bbafrohairbeauty.co.uk",
    usesCart: false
  },
];
