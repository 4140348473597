<template>
  <div>
    <div class="left override-left" v-show="stage === 1">
      <h2>Select your treatment</h2>
      <div class="catWrap">
        <div v-for="category in categories" :key="category.id" class="category">
          <div class="title">
            <h3>{{ category.Name }}</h3>
            <h4
              @click="expandCategory(category.ID)"
              class="expand"
              v-show="isExpanded(category.ID) === undefined"
            >
              +
            </h4>
            <h4
              @click="collapseCategory(category.ID)"
              class="expand"
              v-show="isExpanded(category.ID) !== undefined"
            >
              -
            </h4>
          </div>

          <div
            v-for="treatment in getFilteredTreatments(category.ID)"
            :key="treatment.id"
            class="option"
            v-show="isExpanded(category.ID) !== undefined"
          >
            <div class="title">
              <label
                class="checkbox"
                :class="{ selected: checkIfSelected(treatment.ID) }"
              >
                <input
                  type="checkbox"
                  :value="treatment"
                  v-model="selected"
                  style="opacity: 0; width: 1px"
                />
                {{ treatment.Name }} - {{ formatPrice(treatment.Price) }}
                <i class="tdes">{{ treatment.Description }}</i>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right override-right" v-show="stage === 1">
      <h2>Select your date</h2>
      <v-date-picker
        v-model="date"
        color="pink"
        is-expanded
        :min-date="new Date()"
        @dayclick="getAvailableTimes()"
      />
      <div class="timeWrap">
        <h3>Select time</h3>
        <div v-show="availableTimes.length === 0">
          Please select an alternative day or please contact us and we may be
          able to accommodate timings on this day.
        </div>
        <button
          v-for="allowedTime in availableTimes"
          :key="allowedTime"
          class="timeSelect"
          @click="selectTime(allowedTime)"
          :class="{ selected: checkTimeSelected(allowedTime) }"
        >
          {{ allowedTime }}
        </button>
      </div>

      <div class="selectionWrap">
        <div class="selection">Treatments: {{ getSelectedTreatments() }}</div>
        <div class="priceSelection">Price: {{ getTotalPriceFormatted() }}</div>
        <div class="selection">Start time: {{ bookingTime }}</div>
        <div class="durationSelection">
          Duration:
          {{ getTotalDurationFormatted() }}
        </div>
      </div>

      <button
        class="next-button"
        :disabled="enableNext() === false"
        @click="stageOneSubmit()"
      >
        Next
      </button>
    </div>
    <div class="beauty-card" v-show="stage === 2">
      <FormKit
        type="form"
        v-model="customerForm"
        @submit="stageTwoSubmit"
        submit-label="Book"
      >
        <FormKit
          type="text"
          name="name"
          id="name"
          label="Name"
          help="Your full name"
          placeholder="“Jane Doe”"
        />
        <FormKit
          type="email"
          name="email"
          label="Email address"
          help="Please enter your email address."
          validation-visibility="live"
          placeholder="hello@roseandroguebeauty.co.uk"
        />
        <FormKit
          type="tel"
          label="Phone no"
          name="telephone"
          help="Please enter your preferred phone number."
          placeholder="01234567890"
          minlength="10"
          maxlength="11"
        />
        <FormKit
          type="text"
          name="doorNo"
          id="doorNo"
          label="Address Line 1"
          help="Your Door number/Flat No, street name"
          placeholder="123 My Road"
        />
        <FormKit
          type="text"
          name="postCode"
          id="postCode"
          label="Post Code"
          help="Your Post Code"
          placeholder="GU11 3QJ"
        />
        <FormKit
          type="checkbox"
          label="Do you consent to us storing your submitted information? We will only email you regarding your appointment, we will not share your information with any third parties"
          name="terms"
          :value="gdpr"
          validation="accepted"
          validation-visibility="dirty"
        />
        <FormKit
          type="checkbox"
          label="Would you like to recieve marketing information, such as newsletters, updates & special offers"
          name="marketing"
          :value="marketing"
        />
      </FormKit>
    </div>
  </div>
</template>

<script>
import "v-calendar/dist/style.css";
import { bookTreatments } from "@/utils/bookingOperations";
import { formatTimeToHours, toSqlDate } from "@/utils/time";
import axios from "axios";

export default {
  name: "TreatmentSelection",
  components: {},
  computed: {
    categories() {
      return this.$store.getters.getCategories;
    },
    treatments() {
      return this.$store.getters.getTreatments;
    },
  },
  created() {
    this.$store.dispatch("callCategories");
    this.$store.dispatch("callActiveTreatments");
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2);
      return "£" + val.toString();
    },
    getFilteredTreatments(filterValue) {
      return this.treatments.filter((c) => c.CategoryId == filterValue);
    },
    // Button operations
    enableNext() {
      if (this.stage == 1) {
        return (
          this.getSelectedTreatments().length > 0 &&
          this.date != "" &&
          this.bookingTime != ""
        );
      }
      return false;
    },
    stageOneSubmit() {
      this.stage = this.stage + 1;
    },
    stageTwoSubmit() {
      var booking = {
        totalPrice: this.getTotalPrice(),
        totalDuration: this.getTotalDuration(),
        startTime: `${toSqlDate(this.date)} ${this.bookingTime}:00`,
        endTime: `${toSqlDate(this.date)} 12:00:00`,
      };
      var customer = {
        name: this.customerForm.name,
        dob: "1900-01-01 00:00:00",
        telephone: this.customerForm.telephone,
        email: this.customerForm.email,
        doorNo: this.customerForm.doorNo,
        postCode: this.customerForm.postCode,
        gdprConsent: this.customerForm.terms,
        gift: false, //TODO: Gift card piece
        marketing: this.customerForm.marketing,
      };
      bookTreatments(booking, customer, this.selected)
        .then(() => {
          this.$router.push("/order/success");
        })
        .catch(() => this.$router.push("/order/fail"));
    },
    // Selected Treatments
    checkIfSelected(treatment) {
      return this.selected.find((t) => t.ID == treatment);
    },
    getSelectedTreatments() {
      return this.selected.map((x) => " " + x.Name).toString();
    },
    getTotalPriceFormatted() {
      return this.formatPrice(
        this.selected.reduce((partialSum, a) => partialSum + Number(a.Price), 0)
      );
    },
    getTotalPrice() {
      return this.selected.reduce(
        (partialSum, a) => partialSum + Number(a.Price),
        0
      );
    },
    getTotalDuration() {
      return this.selected.reduce(
        (partialDuration, d) => partialDuration + d.Duration,
        0
      );
    },
    getTotalDurationFormatted() {
      return formatTimeToHours(
        this.selected.reduce(
          (partialDuration, d) => partialDuration + d.Duration,
          0
        )
      );
    },
    async getAvailableTimes() {
      var bookingDate = toSqlDate(this.date);
      var estmatedDuration = this.getTotalDuration();

      await axios
        .post("bookings/time", {
          bookingDate,
          estmatedDuration,
        })
        .then((data) => {
          this.availableTimes = data.data;
        });
    },
    // Categories
    expandCategory(category) {
      this.expandedCategories.push(category);
    },
    collapseCategory(category) {
      const index = this.expandedCategories.indexOf(category);
      if (index > -1) {
        this.expandedCategories.splice(index, 1);
      }
    },
    isExpanded(category) {
      return this.expandedCategories.find((v) => v == category);
    },
    // Time
    selectTime(time) {
      this.bookingTime = time;
    },
    checkTimeSelected(bookingTime) {
      return bookingTime === this.bookingTime;
    },
  },
  data() {
    return {
      stage: 1,
      selected: [],
      expandedCategories: [],
      availableTimes: [],
      date: "",
      showWaxing: false,
      bookingTime: "",
      gdpr: false,
      marketing: false,
      gift: false,
      customerForm: {},
    };
  },
};
</script>
<style lang="less">
.option {
  .title {
    text-align: left;
    label {
      display: inline-block;
      cursor: pointer;
      padding: 10px;
      width: 95%;
      background-color: #fff;
    }
    .checkbox {
      .tdes {
        display: block;
        font-size: 12px;
        padding-left: 10px;
      }
    }

    input {
      display: inline-block;
    }
  }

  .description {
    text-align: left;
    padding: 15px;
    margin-left: 110px;
  }
  margin-bottom: 5px;
}

.priceSelection .selection .durationSelection {
  width: 100%;
  display: flex;
  margin: 10px;
}

.next-button {
  color: #fff;
  cursor: pointer;
}

.beauty-card {
  margin-top: 25px;

  .formkit-outer {
    .formkit-wrapper {
      max-width: 100% !important;
      .formkit-label {
        font-family: "Poppins", sans-serif;
        text-align: left;
      }
      .formkit-inner {
        input {
          font-family: "Poppins", sans-serif;
        }
      }
      button {
        font-family: "Poppins", sans-serif;
        color: #fff;
        cursor: pointer;
        background-color: #000 !important;
        border-radius: 0px !important;
      }
    }
    .formkit-help {
      font-family: "Poppins", sans-serif;
      text-align: left;
    }
  }
}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.override-left {
  width: 48%;
  height: 100%;
  font-family: "Poppins", sans-serif;
  h2 {
    text-align: center;
  }
}
.override-right {
  text-align: left;
  width: 48%;
  height: 100%;
  padding-right: 0%;
  font-family: "Poppins", sans-serif;
  select {
    border: #000 solid 1px;
  }
  .selectionWrap {
    .selection,
    .priceSelection,
    .durationSelection {
      text-align: left;
      height: 30px;
    }
    padding: 25px;
    border-bottom: 1px solid #a0aec0;
  }

  h2,
  h3 {
    text-align: center;
  }
}
.catWrap {
  .category {
    .title {
      h3 {
        float: left;
      }
      h4 {
        float: right;
        cursor: pointer;
      }
    }
  }
  border-top: 1px solid #a0aec0;
  border-bottom: 1px solid #a0aec0;
  padding: 0 25px;
}
.timeWrap {
  h3 {
    height: 25px;
    margin: 5px;
  }
  .timeSelect {
    border: 1px solid #000;
    background-color: #fff;
    text-align: center;
    margin: 2px;
    font-family: "Poppins", sans-serif;
  }
  text-align: center;
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #a0aec0;
  border-bottom: 1px solid #a0aec0;
}

/*--Hacks--*/
.is-right {
  position: relative;
  left: 44%;
}

.selected {
  background-color: #e7e2e2 !important;
}

@media screen and (max-device-width: 799px) {
  .override-left {
    h2 {
      font-size: 20px;
    }
    .catWrap {
      .category {
        .title {
          h3 {
            font-size: 16px;
          }
          h4 {
            font-size: 14px;
          }
        }
      }
      .option {
        .title {
          .checkbox {
            font-size: 13px;
          }
        }
      }
    }
  }
  .override-right {
    h2 {
      font-size: 20px;
    }
    .timeWrap {
      h3 {
        font-size: 16px;
      }
    }
  }
}
</style>
