<template>
  <header>
    <div class="wrapper">
      <div class="logo">
        <div class="">
          <a href="/" itemprop="url" class="site-header__logo-link">
            <img
              class="site-header__logo-image1"
              src="../../assets/logo_280x.webp"
              srcset="
                ../../assets/logo_280x.webp    1x,
                ../../assets/logo_280x@2x.webp
              "
              alt="Spa Envy"
              itemprop="logo"
            />
          </a>
        </div>
      </div>

      <div class="cartArea">
        <ul>
          <li class="cart">
            <router-link to="/cart"
              ><img
                class="cartlogo"
                src="../../assets/cart-1.1s-207px.png"
              />Cart</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="menu_area">
      <div class="wrapper">
        <div id="navigation">
          <div id="navbtn">menu</div>
          <ul class="nav-menu" id="menu-nenu">
            <li class="site-nav__item site-nav--active">
              <a href="/" class="site-nav__link1" data-meganav-type="child">
                Home
              </a>
            </li>

            <li class="site-nav__item">
              <router-link to="/oasis">Oasis Spas</router-link>
            </li>

            <li class="site-nav__item">
              <router-link to="/sunbeach">Sunbeach spas</router-link>
            </li>

            <li class="site-nav__item">
              <router-link to="/spas">Swim Spas</router-link>
            </li>

            <li class="site-nav__item">
              <router-link to="/documentation">Instructions</router-link>
            </li>
            <li class="site-nav__item">
              <router-link to="/about">Contact Us</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<style lang-="less">
header ul,
footer ul {
  list-style-type: none;
}

.nav-menu li .menuarrow {
  display: none;
}

.menu_area {
  margin: 10px 0 0 0;
}

#navbtn {
  display: none;
}

#navigation {
  width: 100%;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.nav-menu li {
  padding: 0 12px;
  margin-bottom: 0;
}

.nav-menu li > a {
  font: 600 15px/45px "Montserrat", sans-serif;
  color: #1496bc;
  white-space: nowrap;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
}

.site-nav__item {
  white-space: normal;
}

.site-nav__item {
  display: inline-block;
  margin: 0;
}

*,
input,
:before,
:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cartArea {
  float: right;
  margin-top: 5px;
  margin-right: -20px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}

.cartlogo {
  width: 44px;
}

@media screen and (max-device-width: 800px) {
  .logo {
    width: 40%;
  }
}

@media screen and (max-device-width: 690px) {
  .nav-menu {
    padding-left: 0;
  }
}

@media screen and (max-device-width: 650px) {
  .nav-menu li {
    padding: 0 10px;
    margin-bottom: 0;
  }

  .nav-menu li > a {
    font: 600 12px/45px "Montserrat", sans-serif;
  }

  .cartArea {
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    margin: 0;
    float: none;
  }
}

@media screen and (max-device-width: 545px) {
  .logo {
    width: 55%;
  }

  .site-nav__item {
    display: block;
  }
}
</style>
