export default [
  {
    id: 1,
    title: "ORS Olive Oil No lye hair relaxer",
    price: 10.5,
    blurb: `<p> </p>
      <p><strong>Information</strong></p><p>The ORS Olive Oil Built-In Protection New Growth No-Lye Hair Relaxer kit is light-sized for touch-ups, short hair styles, and ZONE RELAXING retouching a target section or zone of the head. Olive Oil, historically known as a healing elixir, is rich in essential fatty acids that impart moisture directly into the scalp and hair shaft for stronger, after hair with beautiful shine</p>`,
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 2,
    title: "Dax Coconut Oil (213g)",
    price: 5.5,
    picture:
      "https://i0.wp.com/www.afro-world.co.uk/wp-content/uploads/2020/10/coconut-14.jpg?fit=1039%2C1016&ssl=1",
    listingPictures: [
      "https://i0.wp.com/www.afro-world.co.uk/wp-content/uploads/2020/10/coconut-14.jpg?fit=1039%2C1016&ssl=1",
      "https://i0.wp.com/www.afro-world.co.uk/wp-content/uploads/2020/12/coconut-7.5.jpg?fit=1005%2C1188&ssl=1",
    ],
    blurb: `<p><strong>Information</strong></p>
<p>DAX Coconut Oil enriched with Vitamin E, is a versatile product that can be used as a skin moisturizer, body oil, or hair conditioner. Our unique formula will condition and revitalize dry hair; as well as, smooth and soften rough and dry skin.</p>`,
    category: "haircare",
  },
  {
    id: 3,
    title: "Dabur Amla Hair Oil Anti Dandruff 200ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: `<p><strong>Information</strong></p>
<p>Amla Anti Dandruff Hair Oil helps reduce dandruff and soothes the scalp. Fungae feeds on the scalp's natural oils, causing irritation and flaking. Over time, the scalp becomes dry and itchy leading to dandruff problems. Dabur NEW Amla Anti-Dandruff Hair Oil, with Amla, Lemon, and Rosemary help reduce dandruff, helps nourish & strengthen your hair and helps make hair strong & beautiful.</p>`,
    category: "haircare",
  },
  {
    id: 4,
    title: "Dabur Mia Jasmine Hair oil 200ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: `<p><strong>Information</strong></p>
<p>For many years, this eco-friendly product was thought to be the key to beautiful hair. It is naturally blended with the nourishment of Amla and the fresh fragrance of Jasmine, which helps to strengthen the hair and keeps your hair shining longer.</p>`,
    category: "haircare",
  },
  {
    id: 5,
    title: "Creme Of Nature Professional Detangling & Conditioning Shampoo",
    price: 11.5,
    blurb:
      '<div class="tab-content resp-tab-content resp-tab-content-active" id="tab-description" aria-labelledby="tab_item-0" style="display:block"> \t\t\t\t\t \t<h2>Description</h2>  <div class="mb3"> <div class="dangerous-html mb3">Creme of nature professional shampoo detangle and conditioning detangles and conditions hair in one easy step. This moisturizing shampoo is perfect for chemically treated or damaged hair. Leaves hair full of body and shine. This professional formula detangling/conditioning shampoo detangles and conditions in one easy step .Perfect for chemically treated or damaged hair or hair that is relaxed, color-treated or thermally straightened. An extraordinary natural ingredient blend cleanses and conditions in one easy step and is so rich, no other conditioning treatment is needed Leaves hair smooth and easy to comb. Takes snags and snarls out of wet combing.</div> </div> <div class="mb3"> <div class="dangerous-html mb3"> <ul> <li><span class="a-list-item"><span style="color: #0f1111;">Creme of Nature Professional Detangling and Conditioning Shampoo, 32 oz</span><br> </span></li> <li><span class="a-list-item">Nourishes moisture starved hair</span></li> <li><span class="a-list-item">Perfect for chemically treated or damaged hair</span></li> <li>Leaves hair full of body and shine.</li> <li><span style="color: #0f1111;">Sunflower and Coconut scent&nbsp;</span></li> </ul> </div> </div> \t\t\t\t</div>',
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
  },
  {
    id: 6,
    title: "Hemani Black Seed hair Oil 200ml",
    price: 7.99,
    blurb:
      '<div class="tab-content resp-tab-content resp-tab-content-active" id="tab-description" aria-labelledby="tab_item-0" style="display:block"> \t\t\t\t\t \t<h2>Description</h2>  <p><em><strong>Hemani Black Seed Hair Oil 200ml</strong></em></p> <p>Hemani Black Seed Hair Oil is enriched with castor &amp; coconut that helps make hair strong and healthy.</p> <p><em><strong>Features of using Hemani Black Seed Hair Oil:</strong></em></p> <ul> <li>Black seed oil relieves a headache</li> <li>Help increase hair growth</li> <li>Help in regrowing hair on bald regions of the scalp</li> <li>It can dull or minimize an allergic reaction</li> </ul> \t\t\t\t</div>',
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 7,
    title: "Hemani Black Seed hair Oil 100% Natural 125ml",
    price: 8.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb:
      "<p>100% PURE - Extracted & Prepared from Natural Black Seeds using Cold Press Technology for a 100% Unrefined Nigella Sativa Blackseed Oil. Health Benefits - This Herb is well known for its amazing health benefits such as strengthening the immune system. The Oil compliments ingredients for food cooking, massage, aromatherapy, steaming, drinking, bathing, masking, therapeutic. A medicine cabinet must-have used for 2000 years for generations worldwide. Virgin Cold Pressed - Cold Pressed at 40°C preserving the maximum amount of nutrients A, B, B2, C, Niacin Minerals including Calcium, Potassium, Iron, Zinc, Magnesium & Selenium for better health of children, adults, elders and all the family.</p>",
    category: "haircare",
  },
  {
    id: 8,
    title: "Hemani Ginger Oil 30ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 9,
    title: "Hemani Rose Oil 30ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 10,
    title: "Hemani Jojoba Oil 30ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 11,
    title: "Hemani Jasmin Oil 30ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 12,
    title: "Hemani Carrot Oil 30ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 13,
    title: "Hemani Henna Black 33g",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 14,
    title: "Hemani Henna Live Natural 33g",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 4,
    blurb: "",
    category: "haircare",
  },
  {
    id: 15,
    title: "Hemani Eucalyptus Oil 30ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 16,
    title: "Aliza Red Edition Hair dryer",
    price: 45.0,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: `<div>
    <p>Aliza Red Edition 1875 Hairdryer 2000 Watts</p>
<p>&nbsp;</p>
<p>Hair Dryer with DC Motor</p>
<p>2 Speed Settings</p>
<p>3 Heat settings</p>
<p>Cool Shot Button</p>
<p>Ultralight&nbsp;</p>
<p>Hanging Loop</p>
<p>Removable Filter Cover</p>
<p>Comb &amp; Slim&nbsp;Nozzle</p>
<p>1.8m Length Cable&nbsp;</p>
<p>&nbsp;</p>
  </div>`,
    category: "haircare",
  },
  {
    id: 17,
    title: "Aliza 100% Natural Castor Oil 125ml",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 18,
    title: "Aliza Cream Peroxide 6%/20 vol 1l",
    price: 8.99,
    picture:
      "https://www.jansonwholesale.com/wp-content/uploads/2021/02/Aliza-20-Vol-1000ml-scaled.jpg",
    listingPictures: [],
    blurb: "",
    category: "haircare",
  },
  {
    id: 19,
    title: "Africa Essence Braid Sheen Spray",
    price: 7.2,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb:
      "<p>African Essence Braid Sheen Spray is a blend of natural conditioners with shine additives and powerful itch reducing agents. African Essence Braid Sheen spray hold braids together for a neat, tight braid with a great shine. Direction: Use daily for best results. Spray generously on scalp and hair. Gently massage scalp. Style as desired.</p>",
    category: "haircare",
  },
  {
    id: 20,
    title: "Jamaica Mango & Lime Black castor oil peppermint 118ml",
    price: 9.8,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: `<p>
    SIMPLY THE BEST: With its clean, crisp fragrance, the stimulating scent of Peppermint is a favorite among people everywhere. Only the finest Jamaican castor beans are selected and blended with our Peppermint essential oil to produce our exclusive proprietary blend. Perfect for aromatherapy and essential for everyday use.
SUPERIOR HAIR CARE: Nourishing ingredients replenishes hair moisture and reduces the flakes and itching caused by dry scalp. The perfect oil to help revitalize roots and rejuvenate hair growth adding sheen and life back to damaged dull locks.
LOVE THE SKIN YOU’RE IN: Natural healing properties that immediately hydrate and absorb resulting in healthy supple skin. Also helps to moisturize and soften cuticles, a great addition to your manicure routine. Restore health to your dry skin in just a few drops with this exclusive premium grade oil. Suitable for all skin types.
AROMATHERAPY FOR YOUR MIND AND BODY: Jamaican black castor and peppermint oil is known to provide natural pain relief for cramps, muscle and back pains. Its invigorating and uplifting properties help with mental fatigue, releases tension and alleviates headaches.
    </p>`,
    category: "haircare",
  },
  {
    id: 21,
    title: "Jamaica Mango & Lime Black castor oil tea tree 118ml",
    price: 9.8,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 22,
    title: "Sulphur8 medicated anti dandruff conditioner treatement 356ml",
    price: 7.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 23,
    title: "At One with Nature Sunflower Oil Root Revitaliser",
    price: 7.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 24,
    title: "Vatika Oil fusion permenent hair colour kit (Jet Black) 108ml",
    price: 7.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: `PERMANENT HAIR COLOR : Presence of natural Oil in Vatika naturals Jet Black color kit provides color that lasts longer while leaving hair healthier and revitalized.
    100% GREY COVERAGE: Are you worried about grey hairs! don't worry Vatika hair color comes for the rescue . It will not only help you covering grey hair from root to tip, it also gives natural shine from first use itself.
    NATURAL HAIR COLOR: It is High-intense bold color of Shade 3.6: Auburn, it will provide you up to 3X shinier hair, and with its luminous color it will last up to 8 weeks .
    SHININIG HAIR: With Nourishing Beet Root & Almond Oil , It provides maximum color performance for intense and even coloring with full of beautiful reflects and long lasting shiny Auburn hair color .
    HOW TO USE: Mix the color and cream well, apply from roots to the tips. Leave for 25-35 mins, depending on the result you wish to achieve. Rinse until the water runs clear, apply the nourishing conditioner and rinse again.`,
    category: "haircare",
  },
  {
    id: 25,
    title: "Vatika Oil fusion permenent hair colour kit (Natural Black) 108ml",
    price: 7.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: `PERMANENT HAIR COLOR : Presence of natural Oil in Vatika naturals Natural Black color kit provides color that lasts longer while leaving hair healthier and revitalized.
    100% GREY COVERAGE: Are you worried about grey hairs! don't worry Vatika hair color comes for the rescue . It will not only help you covering grey hair from root to tip, it also gives natural shine from first use itself.
    NATURAL HAIR COLOR: It is High-intense bold color of Shade 3.6: Auburn, it will provide you up to 3X shinier hair, and with its luminous color it will last up to 8 weeks .
    SHININIG HAIR: With Nourishing Beet Root & Almond Oil , It provides maximum color performance for intense and even coloring with full of beautiful reflects and long lasting shiny Auburn hair color .
    HOW TO USE: Mix the color and cream well, apply from roots to the tips. Leave for 25-35 mins, depending on the result you wish to achieve. Rinse until the water runs clear, apply the nourishing conditioner and rinse again.`,
    category: "haircare",
  },
  {
    id: 26,
    title: "Vatika Avacado Shampoo 425ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 27,
    title: "Vatika Almond Multivitamin Hair Oil 300ml",
    price: 6.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 28,
    title: "Cantli Care for Kids Tear Free Nourishing Shampoo",
    price: 6.9,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 29,
    title: "Cantli Care for Kids Nourishing Conditioner",
    price: 6.9,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 30,
    title: "Cantli Shea Butter (Extra hold Edge stay gel)",
    price: 8.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 31,
    title: "Hesh 100% Pure Virgin Black Seed Oil",
    price: 8.99,
    blurb: "",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    category: "haircare",
  },
  {
    id: 32,
    title: "Hesh Rejuvenating muscle & joint message oil",
    price: 8.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 33,
    title: "Africa's Best braid sheen spray with conditioner",
    price: 7.2,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 34,
    title: "Africa's Best Herbal Gro Super (hair & scalp conditioner)",
    price: 6.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 35,
    title:
      "Africa's Best Maximum Strength Super Gro (Hair & Scalp conditioner)",
    price: 6.8,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 36,
    title: "Africa's Best Coconut growth oil",
    price: 5.75,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 37,
    title: "Africa's Best Argan growth oil",
    price: 5.75,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 38,
    title: "Soft & Silk Braid Extension Color: 1B",
    price: 5.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 39,
    title: "Soft & Silk Braid Extension Color: 2",
    price: 6.99,
    picture:
      "https://www.hairdyecolor.co.uk/pub/media/catalog/product/cache/d6d9537d4cf404ef004455ce1552edae/t/h/th-315ub6kVmL_23.jpg",
    listingPictures: [],
    blurb: "",
    category: "extensions",
  },
  {
    id: 40,
    title: "Uncle Jack's Razorless Shaving Cream (Hair removal)",
    price: 5.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 41,
    title: "African Pride Shea Miracle / Twist & Lock Smoothie",
    price: 7.5,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 42,
    title: "African Pride Shea Miracle Bouncy Curls Pudding",
    price: 7.5,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 43,
    title: "African Pride Black Castor Miracle (Hair & scalp sealing))",
    price: 7.2,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 44,
    title: "African Pride Dream Kids (Detangling Moisterising Conditioner)",
    price: 7.25,
    pictureRouteType: "computed",
    numberOfExtraPics: 4,
    blurb: "",
    category: "haircare",
  },
  {
    id: 45,
    title: "African Pride Black Castor Miracle (Hold & Cover Edges)",
    price: 5.2,
    pictureRouteType: "computed",
    numberOfExtraPics: 4,
    blurb: "",
    category: "haircare",
  },
  {
    id: 46,
    title:
      "African Pride Olive Miracle (Anti Breakage) Super hold & Smooth Edges",
    price: 5.2,
    pictureRouteType: "computed",
    numberOfExtraPics: 5,
    blurb: "",
    category: "haircare",
  },
  {
    id: 47,
    title: "African Pride Olive Miracle Pudding",
    price: 7.25,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 48,
    title: "African Pride Olive Miracle Extra Shine Braid Sheen Spray",
    price: 7.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 49,
    title: "African Pride Shea Butter Miracle (Growth Oil)",
    price: 7.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 50,
    title: "Aunt Jackie's Coco Repair",
    price: 8.5,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 51,
    title: "Aunt Jackie's Butter Creme (Intensive Moisture Sealant)",
    price: 5.99,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 52,
    title: "Aunt Jackie's Butter Fusions Curlspell",
    price: 9.99,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 53,
    title: "Aunt Jackie's Butter Fusions Magic Mend (Acai & Honey)",
    price: 9.99,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
  },
  {
    id: 54,
    title: "Aunt Jackie's Flaxseed Recipes (Tame my edges Smoothing gel)",
    price: 5.5,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 55,
    title: "Motions Professional Neutralising Shampoo (947ml)",
    price: 11,
    blurb:
      "<p>Motions Neutralizing Shampoo, restores hair back to its natural pH level while gently removing traces of relaxer. Helps remove mineral buildup and minimize dullness in hair, for luxuriously soft hair that’s full of body, bounce and intense shine. Directions After Relaxing: Thoroughly rinse all traces of relaxer from client's hair. Towel dry the hair. Apply an ample amount of Motions Moisture Plus Conditioner and distribute. After ten minutes, shampoo lightly twice. Proceed with second phase of conditioning. Weekly Hair Cleansing: Motions Neutralizing Shampoo will remove excess oil while maintaining the integrity of the hair. Simply wet hair, lather and rinse.Ingredients Water (aqua), Sodium Lauryl Sulfate, Cocamidopropyl Betaine, Ppg-5-ceteth-10 Phosphate, Disodium Cocoamphodipropionate, Peg-30 Glyceryl Cocoate, Fragrance (parfum), Sodium C14-17 Alkyl Sec Sulfonate, Peg-12 Dimethicone, Polyquaternium-10, Cocotrimonium Chloride, Dmdm Hydantoin, Citric Acid, Peg-120 Methyl Glucose Dioleate, Linalool, Benzyl Salicylate, Butylphenyl Methylpropional, Iodopropynyl Butylcarbamate, Propylene Glycol, Panthenol, Calendula Officinalis Flower Extract, Anthriscus Cerefolium Extract.</p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 56,
    title: "Motions Oil Sheen & Conditioning spray (315g)",
    price: 8.5,
    blurb:
      "<p>Finish Oil Sheen and Conditioning Spray. Instantly moisturizes dry lifeless hair for a natural lasting shine. With Amazing Smell. </p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
  },
  {
    id: 57,
    title: "ECO Styling Gel for all hair types (Olive Oil) 949ml",
    price: 9.99,
    blurb:
      "<p>Eco Style Olive Oil Styling Gel is a featherlight and intensly moisturising gel while still delivering supreme hold. A flake-free gel made with 100% Olive Oil. Weightless texture, maximum flexibility.</p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 58,
    title: "ECO Styling Gel for all hair types (Olive Oil) 473ml",
    price: 5.99,
    blurb:
      "<p>Eco Style Olive Oil Styling Gel is a featherlight and intensly moisturising gel while still delivering supreme hold. A flake-free gel made with 100% Olive Oil. Weightless texture, maximum flexibility.</p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
  },
  {
    id: 59,
    title: "ECO Styling Gel for all hair types (Krystal) 473ml",
    price: 5.99,
    blurb:
      "<p>The Eco Style Krystal Gel is a strong hold styling gel ideal for all hair types and colours. A weightless formula that provides a gravity-defying hold, body and shine while delivering moisture throughout all hair strands. Suitable for natural hair and synthetic hair.</p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 60,
    title: "Got2B Glued Blasting freeze hair spray (300ml)",
    price: 6.5,
    blurb:
      "<p>got2b Glued Hairspray Blasting Freeze Spray 300ml is our iconic and best-selling hairspray. Designed for super strong hold for up to 72 hrs. Vegan and free from silicones, the formula is suitable for every hair type and has a beautiful citrus fragrance. This hairspray secures and sets your hair in seconds, freezing it in place until your next shampoo.</p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 61,
    title: "Got2B Glued Water resistant spiking gel (150ml)",
    price: 4.8,
    blurb:
      "<p>Schwarzkopf got2b Glued Water Resistant Spiking Glue is designed to keep your hair firmly in place, even in high humidity conditions. The long-lasting formula keeps your style in tact up to 72 hours, with a water-resistant finish. Get the hold you want without compromising on comfort with this trusted styling product.</p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 62,
    title: "Got2B Glued Spiking Wax 7.5ml",
    price: 6.5,
    blurb:
      "<p>Schwarzkopf got2b Glued spiking wax is designed to keep your hair firmly in place, even in high humidity conditions. The long-lasting formula keeps your style in tact up to 72 hours, with a water-resistant finish. Get the hold you want without compromising on comfort with this trusted styling product.</p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 63,
    title: "Benjamin's Rubbing Alcahol (70% Isopropyl vol alcahol) 500ml",
    price: 6.99,
    blurb: "",
    category: "groceries",
    pictureRouteType: "external",
    picture:
      "//www.kiyobeauty.com/cdn/shop/products/Screenshot_20220217-125759_Google_488x700.jpg?v=1645102960",
    listingPictures: [],
  },
  {
    id: 64,
    title: "Tropical Sun 100% Pure coconut oil 250ml",
    price: 4.99,
    blurb: "",
    category: "groceries",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 65,
    title: "Palmers Moisture Boost (Strong Roots spray) 150ml",
    price: 5.99,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
  },
  {
    id: 66,
    title: "Palmers Massage Cream for Stretch Marks 125g",
    price: 5.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 67,
    title: "Palmers Coconut Hydrate Body Oil 150ml",
    price: 6.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 68,
    title: "Palmers Moisturises Softens Body Oil 250ml",
    price: 7.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 69,
    title: "Palmers Cocoa Butter Skin Therapy Oil 125ml",
    price: 8.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 70,
    title: "Palmers Coconut hydrate Daily body lotion 400ml",
    price: 7.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
  },
  {
    id: 71,
    title: "Palmers Cocoa butter with Vitamin E 270g",
    price: 9.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 4,
  },
  {
    id: 72,
    title: "American Dream Aloe butter body cream/ Skintone corrector 500ml",
    price: 7.5,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 73,
    title: "American Dream Cocoa butter Body lotion 473ml",
    price: 6.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 74,
    title: "Dark & Lovely Ultra Cholestral intensive treatment 900ml",
    price: 9.99,
    blurb:
      "<p>Dark & Lovely Ultra Cholesterol Conditioning Mask is an intensive conditioning treatment specially formulated with Panthenol and Vitamin E derivative. This unique formula nourishes, moisturizes and helps protect against split ends while gently detangling dry, damaged hair.</p>",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
  },
  {
    id: 75,
    title: "Ingrams Tissue Oil Cream Skin norishing 500ml",
    price: 9.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
  },
  {
    id: 76,
    title: "Shea Moisture Raw Butter soap (Dry skin) 230g",
    price: 11.5,
    blurb: "",
    category: "haircare",
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
  },
  {
    id: 77,
    title: "Ultimate Originals Advanced Formula Mositerising Body Gloss 355ml",
    price: 6.99,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 78,
    title:
      "Ultimate Originals Cocao Butter & Shea Butter Moistering Body Cream 227g",
    price: 6.5,
    blurb: "",
    category: "body",
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
  },
  {
    id: 79,
    title: "XPression Connection (PreStretch) Color: 1B",
    price: 7.99,
    pictureRouteType: "extenral",
    picture:
      "https://www.pakcosmetics.com/images/content/productimgLarge/XPPreStreched2XBraids.jpg",
    blurb: "",
    category: "extensions",
  },
  {
    id: 80,
    title: "XPression Connection (PreStretch) Color: 1",
    price: 7.99,
    pictureRouteType: "extenral",
    picture:
      "https://www.pakcosmetics.com/images/content/productimgLarge/XPPreStreched2XBraids.jpg",
    blurb: "",
    category: "extensions",
  },
  {
    id: 81,
    title: "XPression Connection (PreStretch) Color: 2",
    price: 7.99,
    pictureRouteType: "extenral",
    picture:
      "https://www.pakcosmetics.com/images/content/productimgLarge/XPPreStreched2XBraids.jpg",
    blurb: "",
    category: "extensions",
  },
  {
    id: 82,
    title: "XPression Connection (PreStretch) Color: 4",
    price: 7.99,
    pictureRouteType: "extenral",
    picture:
      "https://www.pakcosmetics.com/images/content/productimgLarge/XPPreStreched2XBraids.jpg",
    blurb: "",
    category: "extensions",
  },
  {
    id: 83,
    title: "XPression Extensions Braids (Kanekalon) Color: 1B/BLEU/1B/1B/30",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 84,
    title: "XPression Extensions Braids (Kanekalon) Color: 1B/350",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 85,
    title: "XPression Extensions Braids (Kanekalon) Color: M44",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 86,
    title: "XPression Extensions Braids (Kanekalon) Color: 1B/27",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 87,
    title: "XPression Extensions Braids (Kanekalon) Color: 1B/33",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 88,
    title: "XPression Extensions Braids (Kanekalon) Color: 1B/99J",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 89,
    title: "XPression Extensions Braids (Kanekalon) Color: B/27",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 90,
    title: "XPression Extensions Braids (Kanekalon) Color: 33",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 91,
    title: "XPression Extensions Braids (Kanekalon) Color: BG",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 92,
    title: "XPression Extensions Braids (Kanekalon) Color: 1B",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 93,
    title: "XPression Extensions Braids (Kanekalon) Color: M-51",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 94,
    title: "XPression Extensions Braids (Kanekalon) Color: 60",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "extensions",
  },
  {
    id: 95,
    title: "HEM Inscene Sticks Lavender 20 Sticks",
    price: 1.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 96,
    title: "HEM Inscene Sticks French Vanilla 20 Sticks",
    price: 1.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 97,
    title: "HEM Inscene Sticks Blessings 20 Sticks",
    price: 1.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 98,
    title: "HEM Inscene Sticks Business 20 Sticks",
    price: 1.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 99,
    title: "HEM Inscene Sticks Gateway to India 20 Sticks",
    price: 1.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 100,
    title: "Clear Essence Skin Beautifying Body Oil 257ml",
    price: 7.2,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "body",
  },
  {
    id: 101,
    title: "Clear Essence Texturising complexion lotion 553g",
    price: 7.2,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "body",
  },
  {
    id: 102,
    title: "Clere Pure Glycerine 200ml",
    price: 3.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "body",
  },
  {
    id: 103,
    title: "Clere Pure Glycerine 100ml",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "body",
  },
  {
    id: 104,
    title: "A3 Tropical for dry skin Pure Glycerine 200ml",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 105,
    title: "Mama Africa's cosmetics Caro light Pure Glycerine",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 106,
    title: "Mama Africa's cosmetics Caro light lait cocoa butter",
    price: 9.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 107,
    title: "Eden Apricot Scrub for Face & Body 454g",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "body",
  },
  {
    id: 108,
    title: "Euthymol Original Toothpase 75ml",
    price: 3.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "groceries",
  },
  {
    id: 109,
    title: "Queen Elisabeth Cocoa butter hand & Body lotion 800ml",
    price: 8.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 110,
    title: "Dudu Osun Black Soap Fresh Fragrance",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb:
      "<p>Made with a rich infusion of essential all-natural ingredients, our black soap is renowned globally for its superior skincare properties. Dudu-Osun black soap is clinically proven to smoothen the skin and reduce skin redness. In a clinical study under dermatological control of thirty (30) subjects, Dudu-Osun black soap proved to significantly improve skin moisturization after 28-days.</p><p> Rich with shea butter, honey, aloe vera, cocoa pod ash and other essential oils, Dudu-Osun black soap is loaded with anti-bacterial, anti-fungal and rejuvenating properties.</p>",
    category: "body",
  },
  {
    id: 111,
    title: "Cocoa butter Even Sheen Creme",
    price: 4.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 112,
    title: "Salon Pro Hair bonding Glue 118ml",
    price: 0,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 113,
    title: "Salon Pro Hair Super Hair bond Glue 118ml",
    price: 0,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "haircare",
  },
  {
    id: 114,
    title: "Salon Pro Bonding Remover 60ml",
    price: 0,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },

  {
    id: 115,
    title: "Africa's Best Liquid Mayonaise leave in Conditioner",
    price: 6.8,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 116,
    title: "Africa's Best Olive & Shea (leave in conditioner)",
    price: 6.6,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 117,
    title: "Africa's Best Olive Oil Shampoo for all hair types",
    price: 7.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 118,
    title: "Africa's Best Olive & Shea Deep Moisteriser Masque",
    price: 8.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 119,
    title: "Africa's Best Chloestrol & Tea Tree Leave in Conditioner",
    price: 7.99,
    pictureRouteType: "external",
    picture: "https://files.ekmcdn.com/loveafro/images/africa-best-cholesterol-tea-tree-oil-conditioner-426g-867-p.gif?w=500&h=500&v=1211a20c-4db8-4e77-a14b-11bd6ef2939b",
    blurb: "",
    category: "haircare",
  },
  {
    id: 120,
    title: "Africa's Best Carrot & Tea Tree Oil Therapy",
    price: 6.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 121,
    title: "Africa's Best Kids 2 in 1 Natural Condtioning Detangler",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 122,
    title: "Africa's Best Kids Hair Nutrition (Protein Enhanced Condtioner)",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 123,
    title: "Africa's Best Honey & Castor Edge Gel",
    price: 6.75,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 124,
    title: "Africa's Best Honey & Castor (Thermal Moisteriser)",
    price: 6.75,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 125,
    title: "ORS Oliver Oil (Fortifying Creme Hair Dress)",
    price: 8.8,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 126,
    title: "ORS Oliver Oil (Wig Grip Spray)",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 127,
    title: "ORS Oliver Oil (Exotic Scalp Oil)",
    price: 6.8,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "haircare",
  },
  {
    id: 128,
    title: "ORS Oliver Oil (Fix it Liqufix Spritz Gel)",
    price: 7.25,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 129,
    title: "ORS Oliver Oil (Lock & Twist Gel)",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 130,
    title: "Vitals Olive Oil Anti breakage No Lye Conditioning Relaxers 213g",
    price: 10.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "haircare",
  },
  {
    id: 131,
    title: "Detol Liquid 500ml",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 132,
    title: "STA SOF FRO Aloe Vera Hand & Body Lotion",
    price: 9.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 133,
    title: "Dudu Osun Black Soap Classic Fragrance",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb:
      "<p>Made with a rich infusion of essential all-natural ingredients, our black soap is renowned globally for its superior skincare properties. Dudu-Osun black soap is clinically proven to smoothen the skin and reduce skin redness. In a clinical study under dermatological control of thirty (30) subjects, Dudu-Osun black soap proved to significantly improve skin moisturization after 28-days.</p><p> Rich with shea butter, honey, aloe vera, cocoa pod ash and other essential oils, Dudu-Osun black soap is loaded with anti-bacterial, anti-fungal and rejuvenating properties.</p>",
    category: "body",
  },
  {
    id: 134,
    title: "Soft & Beautiful Botanicals Texturiser(For sensitive scalps)",
    price: 10.8,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 135,
    title: "Soft n'white Papaya Lightening Soap 200g",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 136,
    title: "Africa's Best Liquid Mayonaise Deep Conditioner",
    price: 7.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "haircare",
  },
  {
    id: 137,
    title: "Vaseline Advanced Repair Body lotion 400ml",
    price: 5.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 138,
    title: "Vaseline Intensive Care Essential Healing Body lotion 400ml",
    price: 5.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 139,
    title: "Vaseline Intensive Care Cocoa Radiant Body lotion 400ml",
    price: 5.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 140,
    title: "Vaseline Pure Petrolum Jelly 250ml",
    price: 4.0,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "groceries",
  },
  {
    id: 141,
    title: "Vaseline Pure Petrolum Jelly 100ml",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "groceries",
  },
  {
    id: 142,
    title: "Johnsons Baby Cotton Buds 200 Buds",
    price: 3.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 143,
    title: "Johnsons Baby Cotton Buds 100 Buds",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "groceries",
  },
  {
    id: 144,
    title: "Rico complexion cream 50g",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 145,
    title: "A3 Lightening Serum Eclaircissant Lemon",
    price: 7.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "body",
  },
  {
    id: 146,
    title: "Hemani Clove Oil Dental 10ml",
    price: 3.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "groceries",
  },
  {
    id: 147,
    title: "Aliza Baby Nursery Jelly Shea Butter Scent",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "Body",
  },
  {
    id: 148,
    title: "Carbolic Soap 125g",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "Body",
  },
  {
    id: 149,
    title: "Rico Lemon Xtra 50g",
    price: 5.5,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "body",
  },
  {
    id: 150,
    title: "Tura Original Germicidal Medicated soap 65g",
    price: 1.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "Body",
  },
  {
    id: 151,
    title: "Dettol Original Bar Soap 100g",
    price: 2.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "Body",
  },
  {
    id: 152,
    title: "A3 Lemon Cream Perfect Glow 25ml",
    price: 4.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "body",
  },
  {
    id: 153,
    title: "Ghana Best Shito Mild Chilli Sauce with Shrimp 250g",
    price: 5.50,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 154,
    title: "Liebe Hot Delicious Pepper Sauce Shito 370g",
    price: 6.50,
    pictureRouteType: "computed",
    numberOfExtraPics: 3,
    blurb: "",
    category: "groceries",
  },
  {
    id: 155,
    title: "Morjon Premium Pilchards in Tomato Sauce",
    price: 6.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 156,
    title: "Jack Mackeral in Tomato Sauce",
    price: 2.10,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 157,
    title: "Africa's Pure Zoni Finest Palm Oil",
    price: 4.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 158,
    title: "Africa's Finest Shito Mild Chilli Sauce with Shrimp 250g",
    price: 6.50,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "groceries",
  },
  {
    id: 159,
    title: "Tropical Sun Crayfish Stock Cubes",
    price: 3.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 2,
    blurb: "",
    category: "groceries",
  },
  {
    id: 160,
    title: "Flex Peanuts",
    price: 3.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 161,
    title: "Cocoyam fufu flour 700g",
    price: 4.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "groceries",
  },
  {
    id: 162,
    title: "Poundo Iyan 680g",
    price: 4.00,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "groceries",
  },
  {
    id: 163,
    title: "Tropiway fufu",
    price: 4.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 164,
    title: "Tropical Sun Instant Ginger Drink 20 Sachets",
    price: 5.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
  {
    id: 165,
    title: "Instant Lemon & Ginger (Blended with Honey Granules) 30 Sachets",
    price: 5.99,
    pictureRouteType: "computed",
    numberOfExtraPics: 0,
    blurb: "",
    category: "groceries",
  },
  {
    id: 166,
    title: "Freshpak Rooibos Infusion Teabags (80 teabags)",
    price: 6.50,
    pictureRouteType: "computed",
    numberOfExtraPics: 1,
    blurb: "",
    category: "groceries",
  },
];
