<template>
  <link rel="stylesheet" type="text/css" :href="customerStyle" />
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <router-view />
</template>

<script>
import { useMeta } from "vue-meta";

export default {
  setup() {
    useMeta({
      title: "BB Beauty and Groceries", // TODO: Fix this in #15
      description: "",
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    });
  },
  created() {
    this.customerStyle = this.$store.getters.getClientInfo?.name + ".css";
  },
};
</script>
<style lang="less">
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=RSaleway&family=Poppins:wght@300&display=swap");
@accent-color: #000;
@background-color: #fff;

a,
a:link,
a:visited,
a:hover {
  color: @accent-color;
  outline: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //color: #2c3e50;
  margin-top: 25px;
  margin-right: 50px;
  margin-left: 50px;
}

h4,
h2,
p,
h3 {
  color: @accent-color;
}

.waxone-reasons {
  background-color: #dcc1bd;
  padding: 24px;
  color: #000;
  span {
    font-size: 20px;
  }
}

@media screen and (max-device-width: 973px) {
  #app {
    margin-top: 15px;
    margin-left: 25px;
    margin-right: 25px;
  }

  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
}

@media screen and (max-device-width: 760px) {
  #app {
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
  }

  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 12px;
  }
  h4 {
    font-size: 10px;
  }
  p {
    font-size: 8px;
  }

  .waxone-reasons {
    span {
      font-size: 13px;
    }
  }
}

@media screen and (max-device-width: 545px) {
  #app {
    margin-top: 5px;
    margin-left: 2.5px;
    margin-right: 2.5px;
  }

  h1 {
    font-size: 14px;
  }
  h2 {
    font-size: 12px;
  }
  h3 {
    font-size: 10px;
  }
  h4 {
    font-size: 8px;
  }
  p {
    font-size: 6px;
  }
}
</style>
