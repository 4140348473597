<template>
  <div class="links">
    <HeaderCommon />
    <div class="gko">
      <table>
        <tr>
          <th>Links</th>
        </tr>
        <tr>
          <td>
            <a
              href="https://geckokeypads.com/k500use"
              class="site-nav__dropdown-link"
              data-meganav-type="child"
              tabindex="-1"
              >Gecko K500</a
            >
          </td>
        </tr>
        <tr>
          <td>
            <a
              href="https://geckokeypads.com/k1000overview"
              class="site-nav__dropdown-link"
              data-meganav-type="child"
              tabindex="-1"
              >Gecko K1000</a
            >
          </td>
        </tr>
        <tr>
          <td>
            <a
              href="https://manuals.plus/balboa/control-panels-manual"
              class="site-nav__dropdown-link"
              data-meganav-type="child"
              tabindex="-1"
              >Balboa control pads</a
            >
          </td>
        </tr>
      </table>
    </div>

    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
  },
};
</script>
<style lang="less">
.gko {
  padding: 20px;
}
table {
  color: #1496bc;
  margin: auto;
}
a {
  color: #1496bc;
}
</style>
