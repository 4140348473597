<template>
  <HeaderCommon />
  <TreatmentSelection />
  <FooterCommon />
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import TreatmentSelection from "@/components/Controls/TreatmentSelection.vue";
import { clients } from "@/data/constants";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    TreatmentSelection,
    FooterCommon,
  },

  data() {
    return {
      client: "",
      constants: clients,
    };
  },
  created() {
    this.client = this.$store.getters.getClientInfo?.name;
    this.constants = clients;
  },
};
</script>
