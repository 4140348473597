import { createStore } from "vuex";
import { formatPrice } from "../utils/money";
import { categoriesStore } from "./modules/categories";
import { clientsStore } from "./modules/clients";
import { treatmentsStore } from "./modules/treatments";

/*TODO: Need to really rewrite this layer
  - Split everything off in their modules
  - Use Namespacing
  - Make a decision around spaenvy stuff
  - Store is used a bit unconventionally at the moment
*/

export default createStore({
  state: {
    cartItems: [],
  },
  getters: {
    getCart(state) {
      return state.cartItems;
    },
    getCartTotal(state) {
      let sum = 0;

      for (let index = 0; index < state.cartItems.length; index++) {
        let cartItem =
          Number(state.cartItems[index].price) *
          Number(state.cartItems[index].quantity);
        sum += cartItem;
      }
      return sum;
    },
    getCartHtml(state) {
      var htmlString = `
      <table style="width:100%;">
      <tr>
        <th style="margin-left: 5px; border: 1px solid">Product</th>
        <th style="margin-left: 5px; border: 1px solid">Quantity</th>
        <th style="margin-left: 5px; border: 1px solid">Price</th>
      </tr>`;

      for (let index = 0; index < state.cartItems.length; index++) {
        let cartIitem = `<tr style="margin-left: 5px; border: 1px solid"><td>
        ${state.cartItems[index].title}
        </td>
        <td style="margin-left: 5px; border: 1px solid">${
          state.cartItems[index].quantity
        }</td>
        <td style="margin-left: 5px; border: 1px solid">${formatPrice(
          state.cartItems[index].price
        )}</td></tr>`;
        htmlString += cartIitem;
      }

      htmlString += "</table>";
      return htmlString;
    },
  },
  mutations: {
    updateCart(state, payload) {
      state.cartItems = payload;
    },
    addToCart(state, payload) {
      state.cartItems.push(payload);
    },
  },
  actions: {},
  modules: {
    categories: categoriesStore,
    clients: clientsStore,
    treatments: treatmentsStore,
  },
});
