export default [
  {
    id: 1001,
    title: "SB 8248 SWIM 3 Person Spa",
    price: 19995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/23/cover/M0AmOzMFUsEFU3KTnKYwotYh97EQ7RPDGAhTUiyI.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/media/master-products/23/cover/M0AmOzMFUsEFU3KTnKYwotYh97EQ7RPDGAhTUiyI.png?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/23/TnWJDBQ0SSZi6QVJLrOqCDduAGiHwIENmZVbondh.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/23/YBy9qil6SJ4zHI70xshgHeVxyajCPSkWYB0EZLNh.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p class="p5" data-mce-fragment="1"></p>
      <h2 class="p1">Specification</h2>
      <p class="p2"><b>2250 x 4100 x 1380mm</b><br></p>
      <p class="p3">3 seats.</p>
      <p class="p3">44 jets. (22 rotary jets &amp; 19 directional hydro jets &amp 3 7.5 inch Boost jets)</p>
      <p class="p3">American Aristech Acrylic.</p>
      <p class="p3">Twin filters for extra filtration performance and full enclosed grill for safety.</p>
      <p class="p3">55 Fibre Optic Lights (colour changing) and 1 large LED light (colour changing)</p>
      <p class="p3">Balboa BBa2 Bluetooth Music with Twin Speakers and Subwoofer.</p>
      <p class="p3">2x Ice buckets.</p>
      <p class="p3">Ozonator: 1x 50mg</p>
      <p class="p3">Electrical Supply Required: 32amp</p>
      <p class="p3">American Balboa TP400 Panel Controls.</p>
      <p class="p3">Heater: 3kW Balboa Heater</p>
      <p class="p3">Pumps: x3 Swim pumps and x1 2hp massage pump.</p>
      <p class="p3">Speakers: 2 x Balboa bba2 with bluetooth</p>
      <p class="p3">5 Cup/Glass holders</p>
      <p class="p3">2 Pillows</p>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 1002,
    title: "SB 8328 SWIM 5 Person Spa",
    price: 28995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/472/cover/KtJdaDIp62nmjZdAjHp1mYsMURz9zs1Yxp3rCpXj.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/media/master-products/472/cover/KtJdaDIp62nmjZdAjHp1mYsMURz9zs1Yxp3rCpXj.png?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/23/YBy9qil6SJ4zHI70xshgHeVxyajCPSkWYB0EZLNh.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p class="p5" data-mce-fragment="1"></p>
      <h2 class="p1">Specification</h2>
      <p class="p2"><b>2200 x 5900 x 1670mm</b><br></p>
      <p class="p3">6 seats.</p>
      <p class="p3">73 jets</p>
      <p class="p3">17 2.5" Point-Blank Water jets</p>
      <p class="p3">23 Rotary hydro jets</p>
      <p class="p3">12 3.5" Rotary Hydro Therapy jets/p>
      <p class="p3">9 3.5" Point-Blank Water jets/p>
      <p class="p3">6 4.5" Point-Blank Water jets</p>
      <p class="p3">3 5" Point-Blank Water jets</p>
      <p class="p3">3 5" Rotary Hydro Therapy jets </p>
      <p class="p3">3 Shoulder massage jets </p>
      <p class="p3">3 9.5" Swim jets</p>
      <p class="p3">6 Massage jets</p>
      <p class="p3">8 Roman fountain jets/p>
      <p class="p3">American Aristech Acrylic.</p>
      <p class="p3">Filters: 2 x Spa / 2 x Swim.</p>
      <p class="p3">Fibre optic top rail light: 26 (Colour Changing) x Spa / 48 (Colour Changing) x Swim </p>
      <p class="p3">Large underwater LED light: 1 (Colour Changing) x Spa / 2 (Colour changing) x Swim </p>
      <p class="p3">Balboa BBa2 Bluetooth Music with Twin Speakers and Subwoofer.</p>
      <p class="p3">Ozonator: 1x spa 1x swim</p>
      <p class="p3">Electrical Supply Required: 32amp</p>
      <p class="p3">American Balboa TP400 Panel Controls.</p>
      <p class="p3">Heater: 3kW Balboa Heater</p>
      <p class="p3">Pumps: x3 Swim pumps and x1 2hp massage pump.</p>
      <p class="p3">Speakers: 2 x Balboa bba2 with bluetooth</p>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 1003,
    title: "SB 8458 SWIM 2 Person Spa",
    price: 19995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/251/cover/WeqAywQd0RbUpXnGDOMgkWdos7Sii3ph0rUhuiWQ.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/media/master-products/251/cover/WeqAywQd0RbUpXnGDOMgkWdos7Sii3ph0rUhuiWQ.png?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p class="p5" data-mce-fraentgm="1"></p>
      <h2 class="p1">Specification</h2>
      <p class="p2"><b>2250 x 4100 x 1410mm</b><br></p>
      <p class="p3">2 seats.</p>
      <p class="p3">3 Swim - Boost jets 7.5</p>
      <p class="p3">American Aristech Acrylic.</p>
      <p class="p3">55 Fibre Optic Lights (colour changing) and 1 large LED light (colour changing)</p>
      <p class="p3">Balboa BBa2 Bluetooth Music with Twin Speakers and Subwoofer.</p>
      <p class="p3">2x Ice buckets.</p>
      <p class="p3">Ozonator: 1x 50mg</p>
      <p class="p3">Electrical Supply Required: 32amp</p>
      <p class="p3">Heater: 3kW Balboa Heater (32amp)</p>
      <p class="p3">Pumps: x3 Swim pumps</p>
      <p class="p3">Speakers: 2 x Balboa bba2 with bluetooth</p>
      <p class="p3">2 Cup/Glass holders</p>
      <p class="p3">2 Pillows</p>`,
    manufacturer: "Sunbeach Spas",
  },
  {
    id: 1004,
    title: "POSEIDON 6.0 DUO ELITE LUXURY",
    price: 35995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/699/cover/MlIIz7e2GehHzdj4jQn8L52ixtjnjVDJPvlt9QUr.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/media/master-products/699/cover/MlIIz7e2GehHzdj4jQn8L52ixtjnjVDJPvlt9QUr.png?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/699/GZegnP8qGQV7NszpApDL732CxarU6HvejbAykNub.png?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/699/dvXDF769fbgTS6q2HdNcdtbI7Ahy0TvHhLRdim6e.png?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p class="p5" data-mce-fraentgm="1"></p>
      <h2 class="p1">Specification</h2>
      <p class="p2"><b>2250 x 5900 x 1540mm</b><br></p>
      <p class="p2"><b>VOLUME: 8500 ltr</b><br></p>
      <p class="p2">WEIGHT DRY: 1407 kg<br></p>
      <p class="p2">SWIMMING JETS: 4 turbo jets</p><br></p>
      <p class="p2">JETS: 42 hydrotherapy jets</p><br></p>
      <p class="p3">3 seats. 1 lounger</p>
      <p class="p3">POWER: 45amp + 21amp</p>
      <p class="p3">American Aristech Acrylic.</p>
      <p class="p3"> Low maintenance UV resistant PVC, Coastal Grey Cabinet</p>
      <p class="p3">Galvanized steel frame.</p>
      <p class="p3"> Waterproof ABS Base.</p>
      <p class="p3"> 2 x Gecko IN.YE5 control system, Gecko IN.K1001 bar top control.</p>
      <p class="p3">Electrical Supply Required: 32amp</p>
      <p class="p3">Heater: Gecko Hi Flow 3kw Heater</p>
      <p class="p3">Pumps: SWIMMING PUMP: 2 x 3hp + 2 x 2hp LX pump    </p>
      <p class="p3">MASSAGE PUMP: 2 x 3hp LX pump</p>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 1005,
    title: "OD-39-PLATINUM-SWIM Pearl Shadow",
    price: 19995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/428/cover/QQ7abaSmwCZ9aO7xOPLLMwLgBtzlV13i07fKlr1i.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/95Yw5r0iM1vvmQ7ZrKW9ddzA3NAkgcBA3R0V7Qjg.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/XXEOHnFvJhPoPREIcuwq906KXdkxXQY0Z54KV5sH.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/keYomtogMGI6Rj5KTqPOA3RfJstB8uHdSdWbRmGQ.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/ngtBZ399viprAnJnnCncpznt2B8CAVdMlCWJev29.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/6PYSxez3WmoHmGUYEhK8aJLta5uM3NcXxeylPgYJ.png?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/SgaKwUG9xsfHjU6nweLcuhLHOLkueFvmx0AdMYYZ.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<h3>Description</h3>
      <p>Model Colour: Pearl Shadow<br></p>
      <p>Size: 3850 x 2200 x 1200mm<br></p>
      <p>Gecko Controls: 3kw heater<br></p>
      <p>Weight Empty: 834kg<br></p>
      <p>Weight Filled: 4834kg<br></p>
      <p>Water Capacity: 4000L<br></p>
      <p>Shell: Lucite Acrylic<br></p>
      <p>Seats: 6<br></p>
      <p>Pumps: 2 x 2.5hp | 2 x 2HP<br></p>
      <p>Circulation: 1<br></p>
      <p>Ozonator: 1<br></p>
      <p>Air control valve: 2<br></p>
      <p>Filters: 2<br></p>
      <p>Shell Foam Insulation: 6 layer shell with 10mm high foam<br></p>
      <p>Side Skirt Insulation: 15mm High Density Full Foam & 4mm Dual-Aluminium Foil<br></p>
      <p>Base: Sealed ABS<br></p>
      <p>Jets: Total 48<br></p>
      <p>6.5" Swimming jets: 4<br></p>
      <p>2.5" Directional hydro therapy jets: 4<br></p>
      <p>3.5" Directional hydro therapy jets: 30<br></p>
      <p>4" Rotary therapy jets: 8<br></p>
      <p>5" Rotary therapy jets: 2<br></p>
      <p>Large underwater LED light: Yes<br></p>
      <p>LED perimeter lights: Yes<br></p>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 1006,
    title: "OD-39-PLATINUM-SWIM Silver",
    price: 19995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/42/cover/qnTYKy9MeXsvj9ybDYBgzfmLKx8acj5oKl4LiHXr.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/95Yw5r0iM1vvmQ7ZrKW9ddzA3NAkgcBA3R0V7Qjg.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/XXEOHnFvJhPoPREIcuwq906KXdkxXQY0Z54KV5sH.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/keYomtogMGI6Rj5KTqPOA3RfJstB8uHdSdWbRmGQ.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/ngtBZ399viprAnJnnCncpznt2B8CAVdMlCWJev29.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<h3>Description</h3>
      <p>Model Colour: Sterling Silver<br></p>
      <p>Size: 3850 x 2200 x 1200mm<br></p>
      <p>Gecko Controls: 3kw heater<br></p>
      <p>Weight Empty: 834kg<br></p>
      <p>Weight Filled: 4834kg<br></p>
      <p>Water Capacity: 4000L<br></p>
      <p>Shell: Lucite Acrylic<br></p>
      <p>Seats: 6<br></p>
      <p>Pumps: 2 x 2.5hp | 2 x 2HP<br></p>
      <p>Circulation: 1<br></p>
      <p>Ozonator: 1<br></p>
      <p>Air control valve: 2<br></p>
      <p>Filters: 2<br></p>
      <p>Shell Foam Insulation: 6 layer shell with 10mm high foam<br></p>
      <p>Side Skirt Insulation: 15mm High Density Full Foam & 4mm Dual-Aluminium Foil<br></p>
      <p>Base: Sealed ABS<br></p>
      <p>Jets: Total 48<br></p>
      <p>6.5" Swimming jets: 4<br></p>
      <p>2.5" Directional hydro therapy jets: 4<br></p>
      <p>3.5" Directional hydro therapy jets: 30<br></p>
      <p>4" Rotary therapy jets: 8<br></p>
      <p>5" Rotary therapy jets: 2<br></p>
      <p>Large underwater LED light: Yes<br></p>
      <p>LED perimeter lights: Yes<br></p>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 1007,
    title: "ET-44 - SUPERSWIM",
    price: 28999,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/42/cover/qnTYKy9MeXsvj9ybDYBgzfmLKx8acj5oKl4LiHXr.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/media/master-products/42/cover/qnTYKy9MeXsvj9ybDYBgzfmLKx8acj5oKl4LiHXr.png?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/XXEOHnFvJhPoPREIcuwq906KXdkxXQY0Z54KV5sH.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/49/eLHkna2XxMXUaaLlGyWkYnxFLLmuO3DOTNXGO0ug.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/428/ngtBZ399viprAnJnnCncpznt2B8CAVdMlCWJev29.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/media/master-products/49/cover/ZsCmgG6Vy7afBeHfyfDHQECkatfCkGurmWuzuCwM.png?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<h3>Features</h3>
      <p>Full foam R18+ Insulation<br></p>
      <p>Ozone mixing tube<br></p>
      <p>Gecko 3kw pack<br></p>
      <p>Spa steps optional<br></p>
      <p>Full out winter bag<br></p>
      <p>Twin filters PURE BLUE<br></p>
      <p>Gecko IN stream2<br></p>
      <p>Description<br></p>
      <p>Model Colour: Sterling Silver<br></p>
      <p>Size: 4400 x 2350 x 1540mm<br></p>
      <p>Gecko Controls: 3kw heater<br></p>
      <p>Water Capacity: 4600L<br></p>
      <p>Shell: Lucite acrylic<br></p>
      <p>Seats: 2<br></p>
      <p>Water pumps:<br></p>
      <p>Pumps: 2 x 3hp 2 x 2HP<br></p>
      <p>Circulation: 1 x 0.35hp<br></p>
      <p>Ozonator: 1 x 50mg<br></p>
      <h3>Safety</h3>
      <p>Air control valve: 2<br></p>
      <p>Filters: 2<br></p>
      <p>Insulation: 6 layer shell with 10mm high foam<br></p>
      <p>Base: Sealed ABS<br></p>
      <p>Water jets:<br></p>
      <p>Jets: Total:34<br></p>
      <p>Hydro jets: 46<br></p>
      <p>Air jets: 12<br></p>
      <p>Swim jets: 4<br></p>
      <p>Atmosphere:<br></p>
      <p>Large underwater LED light: Yes<br></p>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 1008,
    title: "AS-50 -PLATINUM PLUNGE SWIM",
    price: 32000,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/46/cover/A0p0jJqMJnX6fK5jls36DT2fEC977ni3m72akh3B.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/public/master-products/46/1QXtFCUN2aREpYdX9thhDLyyGgbZwUHS6sZ6i4Pg.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/46/5Wc3uwG4zMU2Ctf7t9MdCG2xImXdmVdETLl5f9Ky.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/46/CgRSwOL1IHxwNjDiQiM2l5SKJ9WfO3ITq2GR88Ei.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/46/qlnbN0c0NqjbdOuDrk6buibUPObatTPdVj2Udk44.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/46/8rkXGmxvGVfxrJ9oDNOfWW9mGzaEbMdgCNipwYkh.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/46/Syfn7RMltxDKGEvdooRE6hLJJghy8HFIcwyrUUyY.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/46/2QBwMlwMEDrkrfnXDTzs4M3aaCNyfJM8yJ6wC2Uz.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/46/8to1wTJwXSgg1TeDEtWsUtD0HckDyZoi2KKomVLq.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<h3>Features</h3>
      <p>NEW 10 Speed Inverter multi swim 5.5KW<br></p>
      <p>Full foam R18+ Insulation<br></p>
      <p>Ozone mixing tube<br></p>
      <p>Gecko 3kw pack<br></p>
      <p>Spa steps optional<br></p>
      <p>Full out winter bag<br></p>
      <p>Twin filters PURE BLUE<br></p>
      <p>Gecko IN stream2 with 4 Speakers<br></p>
      <p>3" swim jet pipe ( faster swim )<br></p>
      <h3>Description</h3>
      <p>Model Colour: Sterling Silver<br></p>
      <p>Size: 4800 x 2200 x 1270mm<br></p>
      <p>Gecko Controls: 4kw heater<br></p>
      <p>Weight Empty: 1019kg<br></p>
      <p>Weight Filled: 7019kg<br></p>
      <p>Water Capacity: 6000L<br></p>
      <p>Shell: Lucite acrylic<br></p>
      <p>Pumps: 2 x 3hp 2 x 2HP<br></p>
      <p>Circulation: 1<br></p>
      <p>Ozonator: 1<br></p>
      <p>Filters: 2<br></p>
      <p>Shell Foam Insulation: 6 layer shell with 10mm high foam<br></p>
      <p>Side Skirt Insulation: 15mm High Density Full Foam & 4mm Dual-Aluminium Foil<br></p>
      <p>Base: Sealed Plastic ABS<br></p>
      <p>Top Spa Cover: High Density Lockable Cover<br></p>
      <p>Jets: Total 3<br></p>
      <p>Swim jets: 3<br></p>
      <p>LED perimeter lights: Yes<br></p>
      <p>Sound System: 4 Speakers & Subwoofer<br></p>`,
    manufacturer: "Oasis Spas",
  },
];
