export const formatPrice = function (value, showWholeNumbers) {
  if (showWholeNumbers) {
    value = value / 100;
  }

  // TODO: Multiple Currency coversion
  let pounds = Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  });

  return pounds.format(value);
}
