import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
//import HireView from "../views/HireView.vue";
import OasisView from "../views/OasisView.vue";
import SingularProduct from "../views/SingularProduct.vue";
import DocumentationView from "../views/DocumentationView.vue";
import SpaView from "../views/SpaView.vue";
import CartView from "../views/CartView.vue";
import CheckoutView from "../views/Checkout.vue";
import OrderSuccess from "../views/OrderSuccess.vue";
import OrderFailure from "../views/OrderFailure.vue";
import AboutView from "../views/AboutView.vue";
import SunbeachView from "../views/SunbeachView.vue";
import ReviewsView from "../views/ReviewsView.vue";
import NailsView from "../views/NailsView";
import GallariesView from "../views/GallariesView";
import BookingView from "../views/BookingView";
import ProductsView from "../views/ProductsView";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: CheckoutView,
  },
  {
    path: "/order/success",
    name: "success",
    component: OrderSuccess,
  },
  {
    path: "/order/fail",
    name: "failure",
    component: OrderFailure,
  },
  {
    path: "/product/:id",
    name: "product",
    component: SingularProduct,
    props: true,
  },
  /*---Spa-Envy Only---*/
  /*
  {
    path: "/hire",
    name: "hire",
    component: HireView,
  },*/
  {
    path: "/oasis",
    name: "oasis",
    component: OasisView,
  },
  {
    path: "/sunbeach",
    name: "sunbeach",
    component: SunbeachView,
  },
  {
    path: "/documentation",
    name: "documentation",
    component: DocumentationView,
  },
  {
    path: "/spas",
    name: "spas",
    component: SpaView,
  },
  /*---Drama Queen Only --- */
  {
    path: "/reviews",
    name: "reviews",
    component: ReviewsView,
  },
  {
    path: "/nails",
    name: "nails",
    component: NailsView,
  },
  {
    path: "/galleries",
    name: "galleries",
    component: GallariesView,
  },
  {
    path: "/booking",
    name: "booking",
    component: BookingView,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
  },
  {
    path: "/haircare",
    name: "haircare",
    component: ProductsView,
  },
  {
    path: "/body",
    name: "body",
    component: ProductsView,
  },
  {
    path: "/extensions",
    name: "extensions",
    component: ProductsView,
  },
  {
    path: "/groceries",
    name: "groceries",
    component: ProductsView,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
