<template>
  <div class="brochure">
    <HeaderCommon />
    <div class="banner">
      <h1>{{ failureTitle }}</h1>
    </div>
    <div class="failure">
      <div>
        {{ failureText }}
      </div>
    </div>
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
  },
  created() {
    this.failureText = this.$store.getters.getClientInfo?.failureText;
    this.failureTitle = this.$store.getters.getClientInfo?.failureTitle;
  },
};
</script>
<style lang="less">
.failure {
  margin: auto;
  width: 50%;
}
</style>
