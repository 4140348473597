<template>
  <div>
    <h2 class="headline">
      Our Featured product
    </h2>
    <div class="featured">
      <img :src="picture" />
      <h2>{{ title }}</h2>
      <h4>{{ price }}</h4>
      <p v-html="blurb"></p>
      <button @click="goToProduct()">See More</button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import allProducts from "../../public/data/products";
import { formatPrice } from "@/utils/money";
export default {
  props: {
    id: Number,
  },
  created() {
    const products = ref(allProducts);
    const product = products.value.filter((x) => x.id == this.id)[0];
    this.title = product.title;
    this.price = formatPrice(product.price, false);
    this.picture = product.picture;
    this.blurb = product.blurb;
  },
  methods: {
    goToProduct() {
      this.$router.push("/product/" + this.id);
    },
  },
};
</script>
