<template>
  <div class="footer">
    <FooterSE v-if="name === constants.SPAENVY" />
    <FooterDQ v-if="name === constants.RRB || name === constants.BBG" />
  </div>
</template>

<script>
// @ is an alias to /src
import FooterSE from "@/components/SpaEnvy/FooterSE.vue";
import FooterDQ from "@/components/DramaQueen/FooterDQ.vue";
import { clients } from "@/data/constants";

export default {
  name: "HomeView",
  components: {
    FooterSE,
    FooterDQ,
  },

  data() {
    return {
      name: "",
      constants: clients,
    };
  },
  created() {
    this.name = this.$store.getters.getClientInfo?.name;
    this.constants = clients;
  },
};
</script>
