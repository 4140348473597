<template>
  <div class="home">
    <HeaderCommon />
    <div class="cart">
      <div class="cartHeader">
        <div class="title">Product</div>
        <div class="quantity">Qty</div>
        <div class="price">Price</div>
      </div>
      <div v-for="c in cart" :key="c.id" class="cartItems">
        <div class="title">{{ c.title }}</div>
        <div class="quantity">{{ c.quantity }}</div>
        <div>{{ formatPrice(c.price * c.quantity) }}</div>
      </div>
      <div class="cartTotal">
        <div>Total</div>
        <div>{{ formatPrice(cartTotal) }}</div>
      </div>
    </div>
    <button v-show="cart.length > 0" @click="checkout">Check out</button>
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return "£" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    checkout() {
      this.$router.push("/checkout");
    },
  },
  computed: {
    cart() {
      return this.$store.getters.getCart;
    },
    cartTotal() {
      return this.$store.getters.getCartTotal;
    },
  },
};
</script>
<style lang="less">
.cart {
  color: #1496bc;
  margin: 25px;
  .cartHeader {
    border-bottom: 1px solid #fff;
    margin-top: 15px;
    padding-left: 65px;
    .title {
      width: 350px;
    }
    div {
      display: inline-block;
      margin: 15px;
      font-weight: 750;
      text-align: left;
    }
  }
  .cartItems {
    div {
      display: inline-block;
      margin: 15px;
      text-align: left;
    }
    .title {
      width: 350px;
    }
    border-bottom: 1px solid #fff;
  }
  .cartTotal {
    margin-left: 45%;
    div {
      display: inline-block;
      margin: 15px;
      text-align: left;
    }
  }
}

.quantity {
  width: 35px;
}

.price {
  width: 150px;
}

@media screen and (max-device-width: 820px) {
  .cart {
    margin: 5px;
  }
  .cartHeader {
    display: none;
  }

  .cartItems {
    div {
      margin: 5px;
      text-align: center;
    }
    border-bottom: 1px solid #fff;
  }

  .cartTotal {
    margin-left: 0px !important;
    div {
      display: block;
      margin: 5px;
      text-align: center;
    }
  }
}
</style>
