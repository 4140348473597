import client from "@/data/client.js";

export const clientsStore = {
  state: () => ({ clientInfo: null }),
  actions: {},
  mutations: {},
  getters: {
    getClientInfo(state) {
      if (state.clientInfo == null) {
        state.clientInfo = client.filter(
          (x) => x.name == process.env.VUE_APP_CLIENT
        )[0];
      }
      return state.clientInfo;
    },
  },
};
