<template>
  <div class="home">
    <HeaderCommon />
    <HomePageContent v-if="client === constants.RRB" />
    <BlurbContent v-if="client === constants.SPAENVY" />
    <HomeBlurb v-if="client === constants.BBG" />
    <Carousel
      :autoplay="5000"
      :wrap-around="true"
      v-if="client === constants.SPAENVY"
    >
      <Slide v-for="slide in 3" :key="slide">
        <div class="carousel__item" v-show="slide === 1">
          <img
            class="site-header__logo-image1"
            src="../assets/slide1.jpg"
            alt="Spa Envy"
            itemprop="logo"
          />
        </div>
        <div class="carousel__item" v-show="slide === 2">
          <img
            class="site-header__logo-image1"
            src="../assets/slide2.jpg"
            alt="Spa Envy"
            itemprop="logo"
          />
        </div>
        <div class="carousel__item" v-show="slide === 3">
          <img
            class="site-header__logo-image1"
            src="../assets/slide3.jpg"
            alt="Spa Envy"
            itemprop="logo"
          />
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
    <AwardRack v-if="client === constants.SPAENVY" />
    <FeaturedProduct
      id="2"
      v-if="client === constants.SPAENVY || client === constants.BBG"
    />
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import BlurbContent from "@/components/SpaEnvy/blurbContent.vue";
import HomeBlurb from "@/components/BBBG/HomeBlurb.vue";
import FeaturedProduct from "@/components/featuredProduct.vue";
import AwardRack from "@/components/SpaEnvy/awardRack.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import HomePageContent from "@/components/DramaQueen/HomePageContent.vue";
import { clients } from "@/data/constants";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
    HomePageContent,
    BlurbContent,
    HomeBlurb,
    Carousel,
    Slide,
    Pagination,
    Navigation,
    FeaturedProduct,
    AwardRack,
  },

  data() {
    return {
      client: "",
      constants: clients,
    };
  },
  created() {
    this.client = this.$store.getters.getClientInfo?.name;
    this.constants = clients;
  },
};
</script>
<style lang="less">
.carousel__item {
  width: 100%;
  background-color: #1496bc;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  background-color: #1496bc;
  border: 5px solid white;
}
.carousel__pagination-button {
  background-color: #1496bc;
}
</style>
