<template>
  <div class="contact">
    <HeaderCommon />
    <ContactDetails />
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import ContactDetails from "@/components/SpaEnvy/ContactDetails.vue";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
    ContactDetails,
  },
};
</script>
