export default [
  {
    id: 1,
    title: "HE-570 PLATINUM Sterling Silver",
    price: 11995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/29/cover/XyK4BxytR4N33uHJavAf5FMHrLk7ym0H8UB62SqH.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/public/master-products/29/kkCs67Mv6fJ1uSgkMjUW19SsG6hSb2dZbZbx7sNl.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/29/ZiZG4bT91zOEsfoq1ag39WKQiFxTeYpbqZypROLT.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/29/Ysa7JTDFAqm9Ds0ObyDTpKQStyEd1Qftcf8cEtq6.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/29/sqPdcISSb9LxZxdGLE4v8XgrGAyUobKuPyyfHZsN.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/29/sWoeVPma9KG2tu3g3WHnKbvTqOuggZ543F7DdfrZ.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/29/FE3Mo98ak4qgTnnR142f2Rjg39N1AjtsegbDPaxB.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p> </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 5 persons</li>
    <li>Seating: 3 x hydro-therapy seats and 2 x hydro-massage loungers</li>
    <li>Dimensions: 2180 x 2180 x 920mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Gecko INK506 Large LCD Controls</li>
    <li>Heater: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Everlast Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2 x 3HP/0.26KW Water pump Speed</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2</li>
    <li>Insulation: 6 layer shell with 10mm high foam</li>
    <li>Base: Sealed ABS</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 45 targeted water jet</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED Light: Yes</li>
    <li>LED perimeter and corner lights: Ye</li>
    </ul>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 2,
    title: "HE-760-PLATINUM Storm Clouds",
    price: 12995,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/427/cover/4kJwb5mxp5ifxj0RexQxyAa4p4Or1r4OLIDETqg3.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/public/master-products/427/VQNDY6aKH95WBATfrmVdBk9emQW2Rk7Vw0PZtwOZ.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/427/AHsq3mhToYVYuthazraAHCXe0cdTkZJZVS2Y2yMy.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/427/PcgBRRXedVO28A7nauC4KJxr2R5jqraTNBo2EBkF.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/427/FpY92FZ6U7sKCzld4q4ijOIxuhUROINbp6VaLLfm.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/427/rZoM5byqEcpVCB0ZkKRWpALYMpmSxX7JdZkP0l9G.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/427/GiGVrdQzQH65yuTl2QzM37qnYkTMgwBr52FUOgyq.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 6 persons</li>
    <li>Seating: 5 x hydro-therapy seats and 1 x hydro-massage loungers</li>
    <li>Dimensions: 2300 x 2300 x 920mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Gecko INK506 Large LCD Controls</li>
    <li>Heater: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Everlast Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2 x 3HP/0.26KW Water pump  Speed</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2 </li>
    <li>Insulation: 6 layer shell with 10mm high foam</li>
    <li>Base: Sealed ABS</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 50 targeted water jet</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED Light: Yes</li>
    <li>LED perimeter and corner lights: Yes</li>
    </ul>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 3,
    title: "RA-371 Standard 3 Seater Hot tub",
    price: 5400,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/24/cover/XFcBPgEj5WQSnPwyDpboTnGbHIkB6tmrwhS4M4Jv.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/public/master-products/24/GxKnegzN4MUWvE4VGoZaVwrIiwa0eCSvRyTem0Ry.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/24/nviIuDuTLaVBp8ohucOLL51UF5TPK09XgNvSDQZ5.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/24/faAIgDnuM2j3WO6cPXhU2hKcj1zBnh2U33XNNt8g.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p> </p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 3 persons</li>
    <li>Dimensions: 2000 x 2000 x 740mm</li>
    <li>Power: 13amp Plug and Play</li>
    <li>Control system: Gecko IN.YJ2 pack 2KW LCD Controls</li>
    <li>Heater: 2kw heater</li>
    <li>Acrylic Colour: Sterling Silver</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Everlast Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1x 3HP/0.26KW Water pump Speed</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2</li>
    <li>Insulation: All 4 sides Thermal Lock</li>
    <li>Base: Sealed ABS</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 20 targeted water jet</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED Light: Yes</li>
    <li>LED perimeter and corner lights:Yes</li>
    </ul>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 4,
    title: "RX-170 Wellness 3 Person",
    price: 7495,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/25/cover/88SZPskDbWDw4S6Wolj0Gq729yyDO9YQG6wWUOwn.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [
      "https://sunbeachspas-cms.co.uk/img/public/master-products/25/zyZKR8PbctjTUv3SsjSulOOWtuc3HUBifeK9RRNV.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/25/D1VR1mi5OVOR7TAs9X8stM1On27FNZ3MW37mDxW0.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/25/dhwnRa5qsh4UxKDius4EnCJoVuH7TU9FcTykPyN0.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/25/O5r44oVxmEstyL58mFyugZc7q9XUWz387jXFLlU3.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/25/1wHaxKm1brFLTT97QJpdHS4AVZx1exqQuBMssoib.jpg?w=800&h=800&fit=fit&dpr=2",
      "https://sunbeachspas-cms.co.uk/img/public/master-products/25/1OX4FfPGX0zxlOZEUX4PXx8XOzTZ1BxZmIUvwBTt.jpg?w=800&h=800&fit=fit&dpr=2",
    ],
    blurb: `<p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 3 persons</li>
    <li>Seating: 1x seat & 2x hydro-massage loungers</li>
    <li>Dimensions: 2000 x 1640 x 820mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Gecko IN.K506 Large LCD Controls</li>
    <li>Heater: 3kw heater</li>
    <li>Acrylic Colour: Sterling Silver</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Everlast Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 1x 3HP/0.26KW Water pump Speed</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2</li>
    <li>Insulation: 6 layer shell with 10mm high foam</li>
    <li>Base: Sealed ABS</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 26 targeted water jet</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED Light: Yes</li>
    <li>LED perimeter and corner lights: Yes</li>
    </ul>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 6,
    title: "RX-570 5 Person Hot Tub Oasis Spas",
    price: 8895,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/27/cover/Sn12abdHNqqRJuPYMTFzsnipBmYd6hhdgHsbLStb.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 5 persons</li>
    <li>Seating: 3x hydro-therapy seats and 2x hydro-massage loungers</li>
    <li>Dimensions: 2180 x 2180 x 920mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Gecko IN.K506 Large LCD Controls</li>
    <li>Heater: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Everlast Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2x 3HP/0.26KW Water pump Speed</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2 </li>
    <li>Insulation: 6 layer shell with 10mm high foam</li>
    <li>Base: Sealed ABS</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 48 targeted water jet</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED Light: Yes</li>
    <li>LED perimeter and corner lights: Yes</li>
    </ul>`,
    manufacturer: "Oasis Spas",
  },
  {
    id: 7,
    title: "RX-773 6 Person Hot Tub Oasis Spas",
    price: 9395,
    picture:
      "https://sunbeachspas-cms.co.uk/img/media/master-products/28/cover/KvSNDGPDmmuzTPp0zeFeE52IbDn25gBjNdtCpVBF.png?w=800&h=800&fit=fit&dpr=2",
    listingPictures: [],
    blurb: `<p></p>
    <p><strong>Information</strong></p>
    <ul>
    <li>Seating Capacity: 6 persons</li>
    <li>Seating: 4x hydro-therapy seats and 2x hydro-massage loungers</li>
    <li>Dimensions: 2300 x 2300 x 920mm</li>
    <li>Power: 32amp</li>
    <li>Control system: Gecko INK506 Large LCD Controls</li>
    <li>Heater: 3kw heater</li>
    <li>Acrylic Colour: Silver White Marble</li>
    <li>Shell Material: Aristech™ Acrylic Shell (made in USA)</li>
    <li>Cabinet Colour: Everlast Midnight Black</li>
    </ul>
    <p><strong>Water Pumps</strong></p>
    <ul>
    <li>Pumps: 2x 3HP/0.26KW Water pump Speed</li>
    <li>Circulation: 1 x 0.35hp</li>
    <li>Ozone System: 1 x 50mg</li>
    </ul>
    <p><strong>Safety</strong></p>
    <ul>
    <li>Air control valve: 2</li>
    <li>Filters: 2</li>
    <li>Insulation: 6 layer shell with 10mm high foam</li>
    <li>Base: Sealed ABS</li>
    <li>Cover: Thermal Insulated safety covers with key locks</li>
    </ul>
    <p><strong>Jets</strong></p>
    <ul>
    <li>Number of Jets: 59 targeted water jet</li>
    </ul>
    <p><strong>Atmosphere</strong></p>
    <ul>
    <li>Large underwater LED Light: Yes</li>
    <li>LED perimeter and corner lights: Yes</li>
    </ul>`,
    manufacturer: "Oasis Spas",
  },
];
