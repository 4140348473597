<template>
  <div>
    <div class="gallery">
      <img src="../../assets/DQ/chelson-tamares-CdnZrvMUsBE-unsplash.jpg" />
    </div>
    <div class="small-imageRow">
      <img src="../../assets/DQ/PXL_20230326_135106345_2.jpg" />
      <img src="../../assets/DQ/PXL_20230207_142634836.jpg" />
      <img src="../../assets/DQ/nails.png" />
    </div>
    <div class="small-imageRow">
      <img src="../../assets/DQ/PXL_20230118_204406741.jpg" />
      <img src="../../assets/DQ/cnd-iconic.jpg" />
      <img src="../../assets/DQ/PXL_20230118_204307362.jpg" />
    </div>
    <div class="small-imageRow">
      <img src="../../assets/DQ/PXL_20221224_014838889.PORTRAIT.jpg" />
      <img src="../../assets/DQ/PXL_20230118_204200435.jpg" />
      <img src="../../assets/DQ/PXL_20221202_160308261.jpg" />
    </div>
  </div>
</template>

<style lang="less">
.gallery {
  width: 100%;
  padding-top: 15px;
  img {
    width: 98%;
    margin: 0.5%;
  }
}
.small-imageRow {
  width: 100%;
  padding-top: 15px;
  img {
    width: 32%;
    margin: 0.5%;
    display: inline-block;
  }
}
</style>
