<template>
  <div class="hire">
    <HeaderCommon />
    <div class="container">
      <div class="right">
        <h2>{{ title }}</h2>
        <h3
          v-show="
            name !== constants.SPAENVY ||
            (name === constants.SPAENVY && id < 1000)
          "
        >
          {{ displayPrice(price, wholeNumbers) }}
        </h3>
        <h3 v-show="id > 1000 && name === constants.SPAENVY">
          Starting from {{ displayPrice(price, wholeNumbers) }}
        </h3>
        <hr />
        <input type="number" v-model="qty" v-show="showCart === true" />
        <button @click="addToCart" v-show="showCart === true && id < 1000">
          Add to cart
        </button>
        <button v-show="(showCart === true && id > 1000) || showCart === false">
          To order contact us: {{ email }}
        </button>
        <hr />
        <div class="specs" v-html="blurb"></div>
      </div>
      <div class="left">
        <div v-for="picture in pictureLinks()" :key="picture">
          <img
            :src="picture"
            data-widths="[180, 360, 590, 720, 900, 1080, 1296, 1512, 1728, 2048]"
            data-aspectratio="1.3162162162162163"
            data-sizes="auto"
            alt="Product Picture"
          />
        </div>
      </div>
    </div>
    <FooterCommon />
  </div>
</template>

<script>
// @ is an alias to /src
import { ref } from "vue";
import HeaderCommon from "@/components/HeaderCommon.vue";
import FooterCommon from "@/components/FooterCommon.vue";
import allOasis from "@/data/oasis.js";
import allSpa from "@/data/spas.js";
import allSunbeach from "@/data/sunbeach.js";
import allProducts from "../../public/data/products";
import { clients } from "@/data/constants";
import { formatPrice } from "@/utils/money";

export default {
  name: "HomeView",
  components: {
    HeaderCommon,
    FooterCommon,
  },
  props: ["id"],
  methods: {
    addToCart() {
      var addToCart = {
        id: this.id,
        title: this.title,
        price: this.price,
        quantity: this.qty,
      };
      this.$store.commit("addToCart", addToCart);
      this.$router.push("/cart");
    },
    displayPrice(value, showWholeNumbers) {
      return formatPrice(value, showWholeNumbers);
    },
    pictureLinks() {
      let newPicArray = [];
      if (this.pictureRouteType === "computed") {
        var pictureUrl = this.$store.getters.getClientInfo?.pictureLocation;
        newPicArray.push(pictureUrl + "/pics/"+this.id+"/cover.jpeg");

        for (var i = 1; i <= this.numberOfExtraPics; i++) {
          newPicArray.push(pictureUrl + "/pics/" +this.id + "/"+  i + ".jpeg");
        }
      } else {
         newPicArray = this.pictures;
         newPicArray.push(this.coverpic);
      }
      return newPicArray;
    },
  },
  created() {
    this.name = this.$store.getters.getClientInfo?.name;
    this.constants = clients;
    this.wholeNumbers = this.name == this.constants.SPAENVY;
    this.showCart = this.$store.getters.getClientInfo?.usesCart;
    this.email = this.$store.getters.getClientInfo?.email;

    if (this.name == this.constants.SPAENVY) {
      // Hot tubs are less than 1000
      if (this.id < 200) {
        const products = ref(allOasis);
        const product = products.value.filter((x) => x.id == this.id)[0];
        this.title = product.title;
        this.blurb = product.blurb;
        this.price = product.price;
        this.pictures = product.listingPictures;
      } else if (this.id > 200 && this.id < 1000) {
        const products = ref(allSunbeach);
        const product = products.value.filter((x) => x.id == this.id)[0];
        this.title = product.title;
        this.blurb = product.blurb;
        this.price = product.price;
        this.pictures = product.listingPictures;
      } else {
        const products = ref(allSpa);
        const product = products.value.filter((x) => x.id == this.id)[0];
        this.title = product.title;
        this.blurb = product.blurb;
        this.price = product.price;
        this.pictures = product.listingPictures;
      }
    } else {
      const products = ref(allProducts);
      const product = products.value.filter((x) => x.id == this.id)[0];
      this.title = product.title;
      this.blurb = product.blurb;
      this.price = product.price;
      this.pictures = product.listingPictures;
      this.pictureRouteType = product.pictureRouteType;
      this.numberOfExtraPics = product.numberOfExtraPics;
      this.coverpic = product.picture;
    }
  },
  data() {
    return {
      title: "",
      price: "",
      blurb: "",
      qty: 1,
      mainpicture: "",
      pictures: [],
      name: "", //Client name
      wholeNumbers: false,
      showCart: false,
      pictureRouteType: "",
      numberOfExtraPics: 0,
    };
  },
};
</script>
