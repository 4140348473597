import axios from "axios";

export const bookTreatments = async (booking, customer, treatments) => {
  const { data, status } = await axios.post("bookings/create", {
    totalPrice: booking.totalPrice,
    totalDuration: booking.totalDuration,
    startTime: booking.startTime,
    endTime: booking.endTime,
    customer: customer,
    treatments: treatments,
  });

  if (data && status === 200) {
    return data.result;
  } else {
    throw "Unable to book treatments";
  }
};

export const getAvailableTimesForDate = async (
  bookingDate,
  estmatedDuration
) => {
  return await axios
    .post("bookings/time", {
      bookingDate,
      estmatedDuration,
    })
    .then((data) => {
      return data.result;
    });
};
